var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "digital-collection" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.changeTypeAction },
          model: {
            value: _vm.types,
            callback: function ($$v) {
              _vm.types = $$v
            },
            expression: "types",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "作品", name: "works" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "专辑", name: "album" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c" },
        [
          _c(
            "el-select",
            {
              staticClass: "w200",
              attrs: { placeholder: "专辑筛选", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.seriesId,
                callback: function ($$v) {
                  _vm.seriesId = $$v
                },
                expression: "seriesId",
              },
            },
            [
              _c("el-option", { attrs: { value: 0, label: "非专辑" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 1, label: "专辑" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "专辑名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.seriesName,
                callback: function ($$v) {
                  _vm.seriesName = $$v
                },
                expression: "seriesName",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品哈希" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.hash,
                callback: function ($$v) {
                  _vm.hash = $$v
                },
                expression: "hash",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作者名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.authorName,
                callback: function ($$v) {
                  _vm.authorName = $$v
                },
                expression: "authorName",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "发行方名称" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.issuerName,
                callback: function ($$v) {
                  _vm.issuerName = $$v
                },
                expression: "issuerName",
              },
            },
            [
              _c("el-button", {
                attrs: {
                  slot: "append",
                  icon: "el-icon-search",
                  size: "small",
                },
                on: { click: _vm.goSearch },
                slot: "append",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt20" },
        [
          _c("el-cascader", {
            staticClass: "w200",
            attrs: {
              clearable: "",
              placeholder: "作品分类",
              options: _vm.categoryList,
              props: { expandTrigger: "hover", value: "id", label: "label" },
            },
            on: { change: _vm.goSearch },
            model: {
              value: _vm.categoryId,
              callback: function ($$v) {
                _vm.categoryId = $$v
              },
              expression: "categoryId",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品状态", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-option", { attrs: { value: 0, label: "未发行" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 1, label: "已发行" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "作品属性", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.attr,
                callback: function ($$v) {
                  _vm.attr = $$v
                },
                expression: "attr",
              },
            },
            _vm._l(_vm.attrList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.label },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "w200 ml10",
              attrs: { placeholder: "文件类型", clearable: "" },
              on: { change: _vm.goSearch },
              model: {
                value: _vm.fileType,
                callback: function ($$v) {
                  _vm.fileType = $$v
                },
                expression: "fileType",
              },
            },
            _vm._l(_vm.fileTypeList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { value: item.id, label: item.label },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab flex align-items-c mt10" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addAction },
            },
            [_vm._v("+铸造作品")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "order-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "mini",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            staticClass: "tabPop",
                            attrs: {
                              placement: "top-start",
                              width: "100",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "spBlock onHand",
                                  class: { check: _vm.chkName === "dan" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandle("dan", scope.$index)
                                    },
                                  },
                                },
                                [_vm._v("选中本页")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("el-checkbox", {
                              attrs: {
                                slot: "reference",
                                value:
                                  (_vm.chkName === "dan" &&
                                    _vm.checkedPage.indexOf(
                                      _vm.queryParams.pageNum
                                    ) > -1) ||
                                  _vm.chkName === "duo",
                              },
                              on: { change: _vm.changeType },
                              slot: "reference",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: {
                            value:
                              _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                              (_vm.chkName === "duo" &&
                                _vm.noChecked.indexOf(scope.row.id) === -1),
                          },
                          on: {
                            change: function (v) {
                              return _vm.changeOne(v, scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ID" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.productId))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品属性" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm._f("formatAttr")(row.attr))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品分类", prop: "categoryName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "专辑名称", prop: "seriesName" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发售时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.startTime))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "文件预览" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: row.coverPicture,
                            "preview-src-list": [row.coverPicture],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "源文件" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.fileType != 2 && row.file
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showVideoAction(row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      row.fileType == 1
                                        ? "图片"
                                        : row.fileType == 3
                                        ? "视频"
                                        : "音频"
                                    )
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品编号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.productNumber))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "链类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.blockchainType))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作品哈希" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.hash))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户持仓状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.existingPosition))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发售价格" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.price))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发行总量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.issuedCount))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发售/已售/库存", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.salesCount) +
                              " / " +
                              _vm._s(row.sales) +
                              " / " +
                              _vm._s(row.stock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "预留/库存" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(row.reservedQuantity) +
                              " / " +
                              _vm._s(row.reserveStock)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "限购" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.purchaseQuantity))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("formatIssuance")(row.isIssuance))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序", "min-width": "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100px" },
                          attrs: { min: 0, controls: false },
                          on: {
                            change: function (val) {
                              return _vm.editSortAction(val, scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发行方" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.issuerName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "作者" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.authorName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.createTime))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [_c("div", [_vm._v(_vm._s(row.createBy))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-dropdown",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "el-dropdown-link mr10",
                                staticStyle: {
                                  color: "#1890ff",
                                  "font-size": "12px",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v("\n              链接\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.linkAction(row.productId)
                                      },
                                    },
                                  },
                                  [_vm._v("H5链接")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.QRAction(row.productId)
                                      },
                                    },
                                  },
                                  [_vm._v("二维码")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "m0",
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editAction(row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _vm._v(" "),
                        row.isIssuance
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onchangeIsShow(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.isShow ? "下架" : "上架"))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !row.isIssuance
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.issueAction(row.id)
                                  },
                                },
                              },
                              [_vm._v("发行\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !row.isIssuance && row.blockchainTypeId === 8
                          ? _c(
                              "el-upload",
                              {
                                ref: "myUpload",
                                staticClass: "upload-demo",
                                attrs: {
                                  action: "",
                                  headers: _vm.myHeaders,
                                  "show-file-list": false,
                                  "http-request": function (file) {
                                    return _vm.uploadRequest(file, row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "mini" } },
                                  [_vm._v("导入资产id数组")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.isIssuance
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.increaseQuantity(row.id)
                                  },
                                },
                              },
                              [_vm._v("增发\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !row.isIssuance
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAction(row.id)
                                  },
                                },
                              },
                              [_vm._v("删除\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.hasDrawLots
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showReceiveDataAction(row)
                                  },
                                },
                              },
                              [_vm._v("\n            中签名单")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.hasDrawLots
                          ? _c(
                              "el-button",
                              {
                                staticClass: "m0",
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showEditCountAction(row)
                                  },
                                },
                              },
                              [_vm._v("\n            修改申购显示人数")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 flex justify-e" },
            [
              _c(
                "div",
                { staticClass: "flex-one align-items-c flex" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchUp },
                    },
                    [_vm._v("批量上架\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchDown },
                    },
                    [_vm._v("批量下架\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchIssueAction },
                    },
                    [_vm._v("批量发行")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        icon: "ios-search",
                        label: "default",
                        size: "small",
                      },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("批量删除\n        ")]
                  ),
                  _vm._v(" "),
                  _vm.checkedIds.length > 0 || _vm.allCheck
                    ? _c("el-alert", {
                        staticClass: "w200",
                        staticStyle: { height: "32px" },
                        attrs: {
                          title: _vm.allCheck
                            ? "已选择  " + _vm.tableData.total + "  项"
                            : "已选择  " + _vm.checkedIds.length + "  项",
                          type: "info",
                          "show-icon": "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
                  "page-size": Number(_vm.queryParams.pageSize),
                  "current-page": Number(_vm.queryParams.pageNum),
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showIncreaseQuantity.show,
            "close-on-click-modal": false,
            center: "",
            title: "藏品增发",
            width: "350px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.showIncreaseQuantity, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center" },
            [
              _c("el-input-number", {
                attrs: {
                  precision: 0,
                  min: 1,
                  max: 10000,
                  placeholder: "请输入增发数量",
                  controls: false,
                },
                model: {
                  value: _vm.showIncreaseQuantity.addAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.showIncreaseQuantity, "addAmount", $$v)
                  },
                  expression: "showIncreaseQuantity.addAmount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showIncreaseQuantity.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmIncreaseAction },
                },
                [_vm._v("确认增发")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fileVisible,
            "close-on-click-modal": false,
            "before-close": _vm.closeBeforeAction,
            title: "源文件详情",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fileVisible = $event
            },
          },
        },
        [
          _vm.fileTypes == 1
            ? _c(
                "div",
                [
                  _c("el-image", {
                    staticStyle: { width: "550px" },
                    attrs: { src: _vm.fileShow },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.fileTypes == 3
            ? _c("div", [
                _c("video", {
                  attrs: {
                    src: _vm.fileShow,
                    id: "id_test_video",
                    controls: "",
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.fileTypes == 4
            ? _c("div", [
                _c("audio", {
                  staticStyle: { width: "550px" },
                  attrs: { src: _vm.fileShow, controls: "", autoplay: "" },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showImg,
            "close-on-click-modal": false,
            center: "",
            title: "查看二维码",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImg = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.showImgUrl, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editCountData.show,
            "close-on-click-modal": false,
            center: "",
            title: "修改申购显示人数",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editCountData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-flex-center mt10" },
            [
              _c("div", [_vm._v("抽签数：")]),
              _vm._v(" "),
              _c("el-input-number", {
                attrs: { controls: false },
                model: {
                  value: _vm.editCountData.virtualLotteryCount,
                  callback: function ($$v) {
                    _vm.$set(_vm.editCountData, "virtualLotteryCount", $$v)
                  },
                  expression: "editCountData.virtualLotteryCount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editCountData.show = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editCountAction },
                },
                [_vm._v("确认保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "50%",
            visible: _vm.receiveData.show,
            "append-to-body": "",
            title: "中签名单",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.receiveData, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "h", staticStyle: { margin: "0 20px" } },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function ($event) {
                      return _vm.pageChangeNew(1)
                    },
                  },
                  model: {
                    value: _vm.receiveData.tab,
                    callback: function ($$v) {
                      _vm.$set(_vm.receiveData, "tab", $$v)
                    },
                    expression: "receiveData.tab",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "参与详情", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "中签名单", name: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "download",
              staticStyle: {
                margin: "0 20px 20px 20px",
                position: "relative",
                "z-index": "10",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.exportOrder },
                },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.getExportFileList },
                },
                [_vm._v("查看导出记录")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.receiveData.listLoading
            ? _c(
                "div",
                { staticClass: "receive-list" },
                [
                  _vm.receiveData.tab == 1
                    ? [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.receiveData.listLoading,
                                expression: "receiveData.listLoading",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.receiveData.list,
                              size: "small",
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                label: "用户手机号",
                                "min-width": "200",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(scope.row.phone) +
                                              "\n              "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1057756749
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "签数", "min-width": "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(_vm._s(scope.row.count)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1464173522
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm.receiveData.tab == 2
                    ? [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.receiveData.listLoading,
                                expression: "receiveData.listLoading",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.receiveData.list,
                              size: "small",
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                label: "中签用户",
                                "min-width": "200",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(scope.row.memberNickName) +
                                              " "
                                          ),
                                          scope.row.memberPhone
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      scope.row.memberPhone
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3003865655
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "中签编号", "min-width": "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(scope.row.drawLotsNumber)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2173127702
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "是否购买", "min-width": "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.hasBuy
                                                ? "已购买"
                                                : "未购买"
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3400820760
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "购买时间", "min-width": "140" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("div", [
                                          _vm._v(_vm._s(scope.row.buyTime)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                724354634
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "block mt20",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "page-size": _vm.receiveData.query.pageSize,
                          "current-page": _vm.receiveData.query.pageNum,
                          total: _vm.receiveData.total,
                        },
                        on: { "current-change": _vm.pageChangeNew },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("file-list", { ref: "exportList" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }