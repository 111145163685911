var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "120px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "空投名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入空投名称" },
                    model: {
                      value: _vm.formValidate.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "name", $$v)
                      },
                      expression: "formValidate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空投类型：", prop: "type" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.$route.query.id ? true : false },
                      on: { change: _vm.changeType },
                      model: {
                        value: _vm.formValidate.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "type", $$v)
                        },
                        expression: "formValidate.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("快照空投"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("精准空投"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("自定义名单和数量"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.formValidate.type == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "快照名单：", prop: "snapshotIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "small",
                            placeholder: "请选择快照名单",
                            clearable: "",
                            multiple: "",
                          },
                          on: { change: _vm.snapshotIdsChange },
                          model: {
                            value: _vm.formValidate.snapshotIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "snapshotIds", $$v)
                            },
                            expression: "formValidate.snapshotIds",
                          },
                        },
                        _vm._l(_vm.snapshotList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { value: item.id, label: item.name },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml20" }, [
                        _vm._v(
                          "总人数：共" +
                            _vm._s(_vm.checkSnapshot.totalNum || 0) +
                            "条记录/共" +
                            _vm._s(_vm.checkSnapshot.removalNum || 0) +
                            "人"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#909399",
                        },
                        model: {
                          value: _vm.formValidate.isRepeat,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "isRepeat", $$v)
                          },
                          expression: "formValidate.isRepeat",
                        },
                      }),
                      _vm._v("\n        名单重复时只投放一次\n      "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "空投时间：", prop: "airdropTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "selwidth",
                        attrs: {
                          type: "datetime",
                          placeholder: "选择空投时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.formValidate.airdropTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "airdropTime", $$v)
                          },
                          expression: "formValidate.airdropTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == "2"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户手机号",
                        prop: "preciseMemberPhone",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          min: "11",
                          max: "11",
                          placeholder: "请输入用户手机号",
                        },
                        model: {
                          value: _vm.formValidate.preciseMemberPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formValidate,
                              "preciseMemberPhone",
                              _vm._n($$v)
                            )
                          },
                          expression: "formValidate.preciseMemberPhone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.type == "3"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "导入名单：", prop: "Members" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "uploadImg",
                          staticClass: "upload-demo mr10",
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            action: "#",
                            limit: 1,
                            multiple: "",
                            "on-success": _vm.handleSuccess,
                            "on-error": _vm.handleError,
                            "http-request": _vm.airdropExcels,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("导入")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "text" },
                          on: { click: _vm.downLoad },
                        },
                        [_vm._v("下载导入模板")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml20" }, [
                        _vm._v(
                          "总人数：共" +
                            _vm._s(
                              _vm.formValidate.airdropCustomMembers.length || 0
                            ) +
                            "条记录"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "空投内容：", prop: "airdropContents" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.formValidate.airdropContents,
                        size: "small",
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "type",
                          label: "商品类型",
                          width: "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm._f("formattype")(row.type))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productName",
                          label: "商品名称",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _vm.formValidate.type == 3 && row.type == 3
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "w100",
                                            attrs: { placeholder: "积分类型" },
                                            model: {
                                              value: row.scoreTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "scoreTypeId",
                                                  $$v
                                                )
                                              },
                                              expression: "row.scoreTypeId",
                                            },
                                          },
                                          _vm._l(_vm.typeList, function (m, i) {
                                            return _c("el-option", {
                                              key: "type" + i,
                                              attrs: {
                                                value: m.id,
                                                label: m.name,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(_vm._s(row.productName)),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reserveStock",
                          label: "预留库存",
                          width: "80",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(_vm._s(row.reserveStock || 0)),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm.formValidate.type != 3
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "airdropNum",
                              label: "投放数量",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _vm.formValidate.type == 2 &&
                                      row.type == 1 &&
                                      row.isForNumber
                                        ? _c("div", [
                                            _vm._v(
                                              "\n                投放1个\n              "
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            [
                                              _c("span", [_vm._v("每人投放")]),
                                              _vm._v(" "),
                                              row.type == 3
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      staticClass: "w100",
                                                      attrs: {
                                                        placeholder: "积分类型",
                                                      },
                                                      model: {
                                                        value: row.scoreTypeId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            "scoreTypeId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row.scoreTypeId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.typeList,
                                                      function (m, i) {
                                                        return _c("el-option", {
                                                          key: "type" + i,
                                                          attrs: {
                                                            value: m.id,
                                                            label: m.name,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("el-input", {
                                                staticStyle: { width: "100px" },
                                                attrs: {
                                                  placeholder: "请输入投放数量",
                                                },
                                                model: {
                                                  value: row.airdropNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "airdropNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.airdropNum",
                                                },
                                              }),
                                              _vm._v(" "),
                                              row.type == 3
                                                ? _c("span", [_vm._v("积分")])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              row.type != 3
                                                ? _c("span", [_vm._v("个")])
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3071399314
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formValidate.type == 2
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "isForNumber",
                              label: "按编号空投",
                              "min-width": "150",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.type == 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "flex align-items-c",
                                            },
                                            [
                                              _c("el-switch", {
                                                attrs: {
                                                  "active-color": "#13ce66",
                                                  "inactive-color": "#909399",
                                                },
                                                model: {
                                                  value: scope.row.isForNumber,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "isForNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.isForNumber",
                                                },
                                              }),
                                              _vm._v(" "),
                                              scope.row.isForNumber
                                                ? _c(
                                                    "div",
                                                    { staticClass: "ml10" },
                                                    [
                                                      scope.row.numberPrefix
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row
                                                                  .numberPrefix
                                                              ) + "#"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          width: "100px",
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                          placeholder:
                                                            "请输入编号",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.inputCodeBtn(
                                                              $event,
                                                              scope.$index,
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row.number,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "number",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.number",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "/" +
                                                            _vm._s(
                                                              scope.row
                                                                .issuedCount
                                                            )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              546409470
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.del(scope.row, scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.$route.query.id
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(1)
                            },
                          },
                        },
                        [_vm._v("添加数字藏品")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProducts(2)
                            },
                          },
                        },
                        [_vm._v("添加数字盲盒")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProductsCount(3)
                            },
                          },
                        },
                        [_vm._v("添加积分")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.showAddCoupon },
                        },
                        [_vm._v("添加优惠券")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id && _vm.isShowAddInner
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: { click: _vm.showAddInner },
                        },
                        [_vm._v("添加内丹")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$route.query.id
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addProductsCount(6)
                            },
                          },
                        },
                        [_vm._v("添加成长值")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "短信通知：" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#909399",
                    },
                    model: {
                      value: _vm.formValidate.isMessage,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "isMessage", $$v)
                      },
                      expression: "formValidate.isMessage",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("开启后，给空投的用户发送短信通知")]),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#1890ff",
                        display: "inline-block",
                        "margin-left": "10px",
                        cursor: "pointer",
                      },
                      on: { click: _vm.tipdoash },
                    },
                    [_vm._v("查看通知模板")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", prop: "remarks" } },
                [
                  _c("el-input", {
                    staticClass: "w300",
                    attrs: { placeholder: "请输入备注" },
                    model: {
                      value: _vm.formValidate.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "remarks", $$v)
                      },
                      expression: "formValidate.remarks",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.visibleRecord = true
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.visibleTitle,
            visible: _vm.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.visibleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list mt20" },
            [
              _vm.productForm.types == 1
                ? _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: { placeholder: "专辑筛选", clearable: "" },
                      on: { change: _vm.goSearch },
                      model: {
                        value: _vm.productForm.seriesId,
                        callback: function ($$v) {
                          _vm.$set(_vm.productForm, "seriesId", $$v)
                        },
                        expression: "productForm.seriesId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "非专辑" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 1, label: "专辑" } }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "名称搜索",
                  },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.productForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "name", $$v)
                    },
                    expression: "productForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  key: Math.random(),
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.coverPicture },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseRow(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.choose ? "取消选择" : "选择")
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.productList.pageSize,
                  "current-page": _vm.productList.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.productList.total,
                },
                on: {
                  "size-change": _vm.productFormSizeChange,
                  "current-change": _vm.productFormpageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择产出项",
            visible: _vm.produceDialog,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.produceDialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.produceData } },
            [
              _c("el-table-column", { attrs: { label: "Id", prop: "id" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          attrs: { src: scope.row.image, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户持有" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          { attrs: { underline: false, type: "primary" } },
                          [_vm._v(_vm._s(scope.row.allNum))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "未领取上限", prop: "maxAccount" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { underline: false, type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.selectProduce(scope.row)
                              },
                            },
                          },
                          [_vm._v("选择")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleRecord
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleRecord,
                "close-on-click-modal": false,
                center: "",
                title: "",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleRecord = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { margin: "20px 0" } },
                [
                  _vm._v("\n      操作密码："),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { type: "password", placeholder: "请输入密码" },
                    model: {
                      value: _vm.pwd,
                      callback: function ($$v) {
                        _vm.pwd = $$v
                      },
                      expression: "pwd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Coupon", {
        ref: "coupon",
        attrs: { "current-data": [] },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }