var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "min-height": "100vh" } },
        [
          _c(
            "el-form",
            {
              ref: "formValidate",
              staticClass: "formValidate mt20",
              attrs: {
                rules: _vm.ruleValidate,
                model: _vm.formValidate,
                "label-width": "130px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快照名称：", prop: "name" } },
                [
                  _c("el-input", {
                    staticClass: "selwidth",
                    attrs: { placeholder: "请输入快照名称" },
                    model: {
                      value: _vm.formValidate.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formValidate, "name", $$v)
                      },
                      expression: "formValidate.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "导入名单：" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "myUpload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "",
                        headers: _vm.myHeaders,
                        "http-request": _vm.uploadRequest,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("导入")]
                      ),
                      _vm._v(" "),
                      _vm.formValidate.num && _vm.uploadFlag
                        ? _c("span", [
                            _vm._v(
                              "导入成功！共导入" +
                                _vm._s(_vm.formValidate.num) +
                                "条"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.formValidate.num && _vm.uploadFlag
                        ? _c("span", [_vm._v("导入失败！请检查表格")])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "text" },
                      on: { click: _vm.downLoad },
                    },
                    [_vm._v("下载导入模板")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "拍照类型：", prop: "snapshotType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formValidate.snapshotType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formValidate, "snapshotType", $$v)
                        },
                        expression: "formValidate.snapshotType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("定时拍照"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("动态拍照"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !(
                _vm.formValidate.excelImportList &&
                _vm.formValidate.excelImportList.length
              )
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拍照范围：", prop: "snapshotRange" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !!_vm.$route.query.id },
                          model: {
                            value: _vm.formValidate.snapshotRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "snapshotRange", $$v)
                            },
                            expression: "formValidate.snapshotRange",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("指定持仓"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("总持仓"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("消费总额"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.snapshotRange == 0 ||
              _vm.formValidate.snapshotRange == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拍照条件：", prop: "products" } },
                    [
                      _c("span", [_vm._v("仅需符合其中一个条件")]),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#909399",
                        },
                        model: {
                          value: _vm.formValidate.isSatisfyBoth,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "isSatisfyBoth", $$v)
                          },
                          expression: "formValidate.isSatisfyBoth",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.formValidate.products,
                            size: "small",
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "图片", prop: "coverPicture" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                            },
                                            attrs: { src: row.coverPicture },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3670325040
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "productType", label: "商品类型" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatProductType")(
                                              row.productType
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3543910153
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "productName", label: "商品名称" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("div", [
                                        _vm._v(_vm._s(row.productName)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4122853759
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "checkType", label: "持仓类型" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 0 },
                                          model: {
                                            value: scope.row.checkType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "checkType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.checkType",
                                          },
                                        },
                                        [_vm._v("总持仓")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: 1 },
                                          model: {
                                            value: scope.row.checkType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "checkType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.checkType",
                                          },
                                        },
                                        [_vm._v("持仓天数")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1370617331
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "hasCount",
                              label: "持有数量(需大于等于)",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入持有数量",
                                        },
                                        model: {
                                          value: row.hasCount,
                                          callback: function ($$v) {
                                            _vm.$set(row, "hasCount", $$v)
                                          },
                                          expression: "row.hasCount",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              850298541
                            ),
                          }),
                          _vm._v(" "),
                          !_vm.$route.query.id
                            ? _c("el-table-column", {
                                attrs: { label: "操作" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var row = ref.row
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.productDelete(row)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3039003125
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.$route.query.id
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.addProducts(1)
                                },
                              },
                            },
                            [_vm._v("添加数字藏品")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$route.query.id
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.addProducts(2)
                                },
                              },
                            },
                            [_vm._v("添加数字盲盒")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$route.query.id
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.addProducts(3)
                                },
                              },
                            },
                            [_vm._v("添加专辑")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.snapshotRange == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拍照条件：", prop: "products" } },
                    [
                      _c("span", [_vm._v("总持仓")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("（持有数量需大于等于）")]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w300",
                        attrs: { placeholder: "请输入持有数量" },
                        model: {
                          value: _vm.formValidate.totalPosition,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "totalPosition", $$v)
                          },
                          expression: "formValidate.totalPosition",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.snapshotRange == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拍照条件：", prop: "products" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            size: "mini",
                            disabled: !!_vm.$route.query.id,
                          },
                          model: {
                            value: _vm.formValidate.moneyType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formValidate, "moneyType", $$v)
                            },
                            expression: "formValidate.moneyType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("数藏"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("实物"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("不限"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("消费总额")]),
                          _vm._v(" "),
                          _c("span", [_vm._v("（消费总额需大于等于）")]),
                          _vm._v(" "),
                          _c("el-input", {
                            staticClass: "w300",
                            attrs: { placeholder: "请输入消费总额" },
                            model: {
                              value: _vm.formValidate.totalConsumption,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formValidate,
                                  "totalConsumption",
                                  $$v
                                )
                              },
                              expression: "formValidate.totalConsumption",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.formValidate.snapshotRange == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "交易时间：" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.moneyTime,
                          callback: function ($$v) {
                            _vm.moneyTime = $$v
                          },
                          expression: "moneyTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !(
                _vm.formValidate.excelImportList &&
                _vm.formValidate.excelImportList.length
              )
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label:
                          _vm.formValidate.snapshotType == 1
                            ? "实时快照结束时间"
                            : "拍照时间",
                        prop: "photoTime",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "selwidth",
                        attrs: {
                          type: "datetime",
                          placeholder: "选择时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.formValidate.photoTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formValidate, "photoTime", $$v)
                          },
                          expression: "formValidate.photoTime",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formValidate")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.visibleTitle,
            visible: _vm.visible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.visibleClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "user-list mt20" },
            [
              _vm.productForm.types == 1
                ? _c(
                    "el-select",
                    {
                      staticClass: "w200",
                      attrs: { placeholder: "专辑筛选", clearable: "" },
                      on: { change: _vm.goSearch },
                      model: {
                        value: _vm.productForm.seriesId,
                        callback: function ($$v) {
                          _vm.$set(_vm.productForm, "seriesId", $$v)
                        },
                        expression: "productForm.seriesId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "非专辑" } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { value: 1, label: "专辑" } }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: {
                    size: "small",
                    clearable: "",
                    placeholder: "名称搜索",
                  },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.productForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.productForm, "name", $$v)
                    },
                    expression: "productForm.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  key: Math.random(),
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList.data, size: "small" },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "头像" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.coverPicture },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseRow(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(scope.row.choose ? "取消选择" : "选择")
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.productList.pageSize,
                  "current-page": _vm.productList.pageNum,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.productList.total,
                },
                on: {
                  "size-change": _vm.productFormSizeChange,
                  "current-change": _vm.productFormpageChange,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }