var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.changeTypeAction },
              model: {
                value: _vm.types,
                callback: function ($$v) {
                  _vm.types = $$v
                },
                expression: "types",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "作品", name: "works" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "专辑", name: "album" } }),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "盲盒", name: "blind" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab flex align-items-c" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    size: "small",
                    "label-position": "left",
                    "label-width": "60px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { type: "button" },
                          on: {
                            change: function ($event) {
                              return _vm.getList(1)
                            },
                          },
                          model: {
                            value: _vm.queryParams.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "status", $$v)
                            },
                            expression: "queryParams.status",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: null } }, [
                            _vm._v("全部\n              "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.all
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: true } }, [
                            _vm._v("开启寄售\n              "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.waitCount
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("el-radio-button", { attrs: { label: false } }, [
                            _vm._v("关闭寄售\n              "),
                            _vm.tableData.statistical
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "(" + _vm.tableData.statistical
                                        ? _vm.tableData.statistical.overCount
                                        : 0 + ")"
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tab flex align-items-c mt10" },
            [
              _c(
                "el-input",
                {
                  staticClass: "w200",
                  attrs: { size: "small", placeholder: "作品名称" },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.queryParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "name", $$v)
                    },
                    expression: "queryParams.name",
                  },
                },
                [
                  _c("el-button", {
                    attrs: {
                      slot: "append",
                      icon: "el-icon-search",
                      size: "small",
                    },
                    on: { click: _vm.goSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-cascader", {
                staticClass: "w200 ml10",
                attrs: {
                  size: "small",
                  placeholder: "作品分类",
                  clearable: "",
                  options: _vm.categoryList,
                  props: {
                    expandTrigger: "hover",
                    value: "id",
                    label: "label",
                  },
                },
                on: { change: _vm.goSearch },
                model: {
                  value: _vm.queryParams.categoryId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "categoryId", $$v)
                  },
                  expression: "queryParams.categoryId",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "w200 ml10",
                  attrs: {
                    placeholder: "文件类型",
                    clearable: "",
                    size: "small",
                  },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.queryParams.fileType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "fileType", $$v)
                    },
                    expression: "queryParams.fileType",
                  },
                },
                _vm._l(_vm.fileTypeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.label },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "ml20",
                  attrs: { placeholder: "星级", clearable: "" },
                  on: { change: _vm.goSearch },
                  model: {
                    value: _vm.queryParams.starLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "starLevel", $$v)
                    },
                    expression: "queryParams.starLevel",
                  },
                },
                _vm._l(
                  Array.from({ length: 11 }, function (_, index) {
                    return index
                  }),
                  function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { label: item + "星", value: item },
                    })
                  }
                ),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  staticClass: "order-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData.data,
                    size: "mini",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "作品图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.image },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作品名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.name))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "星级", width: "150" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: {
                                  change: function ($event) {
                                    return _vm.chargeCommisionRateAction(row)
                                  },
                                },
                                model: {
                                  value: row.starLevel,
                                  callback: function ($$v) {
                                    _vm.$set(row, "starLevel", $$v)
                                  },
                                  expression: "row.starLevel",
                                },
                              },
                              _vm._l(
                                Array.from({ length: 11 }, function (_, index) {
                                  return index
                                }),
                                function (item) {
                                  return _c("el-option", {
                                    key: item,
                                    attrs: { label: item + "星", value: item },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行价" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v("￥" + _vm._s(row.price))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "发行数量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.issuedCount))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "持仓用户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.holderAction(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.positionUserCount))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "el-table-column",
                    {
                      attrs: { width: "150" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              row.id == _vm.tableCli
                                ? _c(
                                    "div",
                                    [
                                      _c("el-input-number", {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          controls: false,
                                          precision: 0,
                                        },
                                        model: {
                                          value: row.artificialCount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "artificialCount",
                                              $$v
                                            )
                                          },
                                          expression: "row.artificialCount",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            underline: false,
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateUserCount(row)
                                            },
                                          },
                                        },
                                        [_vm._v("保存")]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.artificialCount
                                          ? row.artificialCount
                                          : row.positionProductCount
                                      ) + "\n            "
                                    ),
                                  ]),
                              _vm._v(" "),
                              row.id != _vm.tableCli
                                ? _c(
                                    "el-link",
                                    {
                                      staticStyle: {
                                        "vertical-align": "baseline",
                                      },
                                      attrs: {
                                        underline: false,
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClick(row)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _vm._v("\n              持仓作品\n              "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "您可通过修改自定义持仓数修改实时持仓量，修改后保存即时生效，自定义持仓数为空时，则显示真实时实持仓",
                                  placement: "top-start",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-question" })]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "寄售用户" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.userAction(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.totalUser) + "人")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "寄售数量" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.quantity))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("formatStatus")(row.status))
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { "min-width": "300" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm._v("\n            服务费\n            "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "快照名单有重复时，按最低费率计算",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._l(scope.row.charges, function (val, index) {
                              return _c("div", [
                                val.type == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticStyle: { width: "130px" },
                                            attrs: { size: "mini" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.chargeRateAction(val)
                                              },
                                            },
                                            model: {
                                              value: val.chargeRate,
                                              callback: function ($$v) {
                                                _vm.$set(val, "chargeRate", $$v)
                                              },
                                              expression: "val.chargeRate",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("%"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "mt10 w100",
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                            attrs: {
                                              size: "mini",
                                              placeholder: "快照名单",
                                              clearable: "",
                                            },
                                            model: {
                                              value: val.snapshotId,
                                              callback: function ($$v) {
                                                _vm.$set(val, "snapshotId", $$v)
                                              },
                                              expression: "val.snapshotId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.snapshotList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  value: item.id,
                                                  label: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-input",
                                          {
                                            staticStyle: { width: "130px" },
                                            attrs: { size: "mini" },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.chargeRateAction(val)
                                              },
                                            },
                                            model: {
                                              value: val.chargeRate,
                                              callback: function ($$v) {
                                                _vm.$set(val, "chargeRate", $$v)
                                              },
                                              expression: "val.chargeRate",
                                            },
                                          },
                                          [
                                            _c("template", { slot: "append" }, [
                                              _vm._v("%"),
                                            ]),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              display: "inline-block",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.chargeDeleteAction(
                                                  val,
                                                  index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                              ])
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addChargeRate(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("+添加规则\n              ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "分佣比例", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _vm._v("\n            分佣比例\n            "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "该比例是对服务费中的分成比例",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "110px" },
                                attrs: { size: "mini" },
                                on: {
                                  blur: function ($event) {
                                    return _vm.chargeCommisionRateAction(
                                      scope.row
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.commissionRatio,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "commissionRatio", $$v)
                                  },
                                  expression: "scope.row.commissionRatio",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("%"),
                                ]),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "禁售编号", "min-width": "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.banned))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "限价设置", "min-width": "680" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "4px" } },
                              [
                                _vm._v(
                                  "\n              开启求购\n              "
                                ),
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": true,
                                    "inactive-value": false,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onHasWantbuy(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasWantBuy,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasWantBuy", $$v)
                                    },
                                    expression: "scope.row.hasWantBuy",
                                  },
                                }),
                                _vm._v(" "),
                                scope.row.hasWantBuy
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onChangeHasWantbuy(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "求购价：" +
                                            _vm._s(
                                              scope.row.wantBuyLowPrice +
                                                " - " +
                                                scope.row.wantBuyHighPrice
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.hasWantBuy
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml5",
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setBuySetting(
                                                scope.row.id,
                                                "1"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("求购配置"),
                                          scope.row.wantBuyConfigCount
                                            ? [
                                                _vm._v(
                                                  "(已设置" +
                                                    _vm._s(
                                                      scope.row
                                                        .wantBuyConfigCount
                                                    ) +
                                                    "个套餐)"
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "ml5",
                                      staticStyle: { "font-size": "13px" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.purchasegSetting(
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("拆分求购单设置")]
                                  ),
                                ],
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "4px" } },
                              [
                                _vm._v(
                                  "\n              是否自动买入\n              "
                                ),
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": true,
                                    "inactive-value": false,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onHasAutoWantbuy(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.hasAutoWantBuy,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasAutoWantBuy", $$v)
                                    },
                                    expression: "scope.row.hasAutoWantBuy",
                                  },
                                }),
                                _vm._v(" "),
                                scope.row.hasAutoWantBuy
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          cursor: "pointer",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onChangeHasWantbuy(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "买入价：" +
                                            _vm._s(
                                              scope.row.autoWantBuyLowPrice +
                                                " - " +
                                                scope.row.autoWantBuyHighPrice
                                            )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.hasAutoWantBuy
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml5",
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setBuySetting(
                                                scope.row.id,
                                                "2"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("自动买入配置"),
                                          scope.row.autoBuyConfigCount
                                            ? [
                                                _vm._v(
                                                  "(已设置" +
                                                    _vm._s(
                                                      scope.row
                                                        .autoBuyConfigCount
                                                    ) +
                                                    "个套餐)"
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "flex-shrink": "0",
                                    "mar·gin-right": "5px",
                                  },
                                },
                                [_vm._v("寄售类型")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { flex: "1" } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeListTypeAction(
                                            scope.row
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.marketSelectType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "marketSelectType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scope.row.marketSelectType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 1,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("购买")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 2,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("空投")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 3,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("免费领取")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 4,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("寄售购买")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 5,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("合成")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 6,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("兑换码")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 7,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("拉新互助领取")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            label: 8,
                                            indeterminate: false,
                                          },
                                        },
                                        [_vm._v("抽奖")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(scope.row.pirceLimits, function (val) {
                              return _c("div", [
                                _c(
                                  "div",
                                  [
                                    _c("span", [_vm._v("开始时间")]),
                                    _vm._v(" "),
                                    _c("el-date-picker", {
                                      staticClass: "ml5 mr5",
                                      staticStyle: { width: "190px" },
                                      attrs: {
                                        type: "datetime",
                                        size: "mini",
                                        format: "yyyy-MM-dd HH:mm:ss",
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                        placeholder: "选择开始时间",
                                      },
                                      on: {
                                        change: function (time) {
                                          return _vm.limitsAction(val)
                                        },
                                      },
                                      model: {
                                        value: val.startDate,
                                        callback: function ($$v) {
                                          _vm.$set(val, "startDate", $$v)
                                        },
                                        expression: "val.startDate",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "ml5 mr5",
                                        staticStyle: { width: "100px" },
                                        attrs: { size: "mini" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.limitsAction(val)
                                          },
                                        },
                                        model: {
                                          value: val.getType,
                                          callback: function ($$v) {
                                            _vm.$set(val, "getType", $$v)
                                          },
                                          expression: "val.getType",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "全部", value: "" },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "购买", value: 1 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "空投", value: 2 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "免费领取",
                                            value: 3,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "寄售购买",
                                            value: 4,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "合成", value: 5 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "兑换码", value: 6 },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: {
                                            label: "拉新互助领取",
                                            value: 7,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-option", {
                                          attrs: { label: "抽奖", value: 8 },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w100 ml5 mr5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "最低限价",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.limitsAction(val)
                                        },
                                      },
                                      model: {
                                        value: val.lowestLimit,
                                        callback: function ($$v) {
                                          _vm.$set(val, "lowestLimit", $$v)
                                        },
                                        expression: "val.lowestLimit",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      staticClass: "w100 ml5 mr5",
                                      attrs: {
                                        size: "mini",
                                        placeholder: "最高限价",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.limitsAction(val)
                                        },
                                      },
                                      model: {
                                        value: val.highestLimit,
                                        callback: function ($$v) {
                                          _vm.$set(val, "highestLimit", $$v)
                                        },
                                        expression: "val.highestLimit",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          display: "inline-block",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.limitsDeleteAction(
                                              val,
                                              _vm.index,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "font-size": "12px" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addPriceLimits(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("+添加规则\n              ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "推荐" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-value": true,
                                "inactive-value": false,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.chargeCommisionRateAction(
                                    scope.row
                                  )
                                },
                              },
                              model: {
                                value: scope.row.hasRecommend,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "hasRecommend", $$v)
                                },
                                expression: "scope.row.hasRecommend",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "排序", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "80px" },
                              attrs: { size: "mini", controls: false },
                              on: {
                                change: function ($event) {
                                  return _vm.chargeCommisionRateAction(
                                    scope.row
                                  )
                                },
                              },
                              model: {
                                value: scope.row.recommendSort,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "recommendSort", $$v)
                                },
                                expression: "scope.row.recommendSort",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", fixed: "right", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml10",
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.statusAction(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(row.status ? "关闭" : "开启") +
                                        "寄售"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downAction(row)
                                      },
                                    },
                                  },
                                  [_vm._v("下架所有藏品")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setAction(row)
                                      },
                                    },
                                  },
                                  [_vm._v("设置禁售")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20 flex justify-e" },
                [
                  _c("div", { staticClass: "flex-one align-items-c flex" }),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "page-sizes": [1, 10, 20, 40, 60, 80, 100, 200, 400],
                      "page-size": _vm.queryParams.pageSize,
                      "current-page": _vm.queryParams.pageNum,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tableData.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.visibleBannedStatus
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.visibleBannedStatus,
                "close-on-click-modal": false,
                title: "设置禁售",
                width: "600px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visibleBannedStatus = $event
                },
              },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.bannedStatus.banned,
                  callback: function ($$v) {
                    _vm.$set(_vm.bannedStatus, "banned", $$v)
                  },
                  expression: "bannedStatus.banned",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("请输入禁售编号，多个用“ , ”逗号隔开")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-flex-center mt20" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.confirmBannedStatusAction },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isHasWantBuy,
            "close-on-click-modal": false,
            title: "求购限价规则",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isHasWantBuy = $event
            },
            close: _vm.onCloseHasWantBuy,
          },
        },
        [
          _c(
            "div",
            [
              _vm._v("\n      最低价：\n      "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { type: "number" },
                model: {
                  value: _vm.wantBuyLowPrice,
                  callback: function ($$v) {
                    _vm.wantBuyLowPrice = $$v
                  },
                  expression: "wantBuyLowPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _vm._v("\n      最高价：\n      "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { type: "number" },
                model: {
                  value: _vm.wantBuyHighPrice,
                  callback: function ($$v) {
                    _vm.wantBuyHighPrice = $$v
                  },
                  expression: "wantBuyHighPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.chargeCommisionRateAction(
                        _vm.currentInfo,
                        "hasWantBuy"
                      )
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isHasAutoWantBuy,
            "close-on-click-modal": false,
            title: "自动买入规则",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isHasAutoWantBuy = $event
            },
            close: _vm.onCloseHasAutoWantBuy,
          },
        },
        [
          _c(
            "div",
            [
              _vm._v("\n      最低价：\n      "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { type: "number" },
                model: {
                  value: _vm.autoWantBuyLowPrice,
                  callback: function ($$v) {
                    _vm.autoWantBuyLowPrice = $$v
                  },
                  expression: "autoWantBuyLowPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _vm._v("\n      最高价：\n      "),
              _c("el-input", {
                staticClass: "w200",
                attrs: { type: "number" },
                model: {
                  value: _vm.autoWantBuyHighPrice,
                  callback: function ($$v) {
                    _vm.autoWantBuyHighPrice = $$v
                  },
                  expression: "autoWantBuyHighPrice",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onSubmit()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.visibleUser
        ? _c("User", {
            attrs: { visibleUser: _vm.visibleUser, productId: _vm.productId },
            on: { userClose: _vm.userClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.visibleHolder
        ? _c("Holder", {
            attrs: {
              visibleHolder: _vm.visibleHolder,
              types: 2,
              productId: _vm.productId,
            },
            on: { holderClose: _vm.holderClose },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.buySettingData.show,
            "close-on-click-modal": false,
            title: _vm.buySettingData.type == 1 ? "求购配置" : "自动买入配置",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.buySettingData, "show", $event)
            },
            close: function ($event) {
              _vm.buySettingData.show = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt10 mb20" },
            [
              _vm._v("\n      开启套餐："),
              _c("el-switch", {
                model: {
                  value: _vm.buySettingData.isOpen,
                  callback: function ($$v) {
                    _vm.$set(_vm.buySettingData, "isOpen", $$v)
                  },
                  expression: "buySettingData.isOpen",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.buySettingData.data, function (m, i) {
            return _c("div", { key: i, staticClass: "package-item" }, [
              _c("div", { staticClass: "flex align-items-c" }, [
                _c(
                  "div",
                  { staticClass: "flex align-items-c flex-one" },
                  [
                    _c("span", [_vm._v("套餐有效天数：")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "w100",
                      attrs: { type: "number", placeholder: "请输入" },
                      model: {
                        value: m.day,
                        callback: function ($$v) {
                          _vm.$set(m, "day", $$v)
                        },
                        expression: "m.day",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "ml5" }, [_vm._v("天")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "flex align-items-c yuan" }, [
                  i == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "mr5",
                          on: { click: _vm.addDataAction },
                        },
                        [_vm._v("+")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  i > 0
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.removeDataAction(i)
                            },
                          },
                        },
                        [_vm._v("-")]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c mt10" },
                [
                  _c("span", [_vm._v("兑换消耗积分：")]),
                  _vm._v(" "),
                  _c("el-switch", {
                    model: {
                      value: m.openPoint,
                      callback: function ($$v) {
                        _vm.$set(m, "openPoint", $$v)
                      },
                      expression: "m.openPoint",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticClass: "ml10",
                      attrs: {
                        placeholder: "选择积分类型",
                        disabled: !m.openPoint || !_vm.buySettingData.isOpen,
                      },
                      model: {
                        value: m.scoreTypeId,
                        callback: function ($$v) {
                          _vm.$set(m, "scoreTypeId", $$v)
                        },
                        expression: "m.scoreTypeId",
                      },
                    },
                    _vm._l(_vm.typeList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "w200 ml10",
                    attrs: {
                      type: "number",
                      placeholder: "请输入消耗积分数",
                      disabled: !m.openPoint || !_vm.buySettingData.isOpen,
                    },
                    model: {
                      value: m.pointNum,
                      callback: function ($$v) {
                        _vm.$set(m, "pointNum", $$v)
                      },
                      expression: "m.pointNum",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex align-items-c mt10" },
                [
                  _c("span", [_vm._v("兑换消耗藏品：")]),
                  _vm._v(" "),
                  _c("el-switch", {
                    model: {
                      value: m.openProduct,
                      callback: function ($$v) {
                        _vm.$set(m, "openProduct", $$v)
                      },
                      expression: "m.openProduct",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: {
                        type: "text",
                        disabled: !m.openProduct || !_vm.buySettingData.isOpen,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showChangeProduct(i)
                        },
                      },
                    },
                    [_vm._v("选择藏品")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { overflow: "hidden" } },
                _vm._l(m.product, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "mt10 flex align-items-c" },
                    [
                      _c("img", {
                        staticStyle: { "border-radius": "4px", width: "50px" },
                        attrs: { src: item.image + "!120a" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml10 mr10 flex-one line1" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "w200",
                        attrs: {
                          type: "number",
                          placeholder: "请输入消耗数量",
                        },
                        model: {
                          value: item.count,
                          callback: function ($$v) {
                            _vm.$set(item, "count", $$v)
                          },
                          expression: "item.count",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            cursor: "pointer",
                            "font-size": "14px",
                            "margin-left": "10px",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeProduct(i, index)
                            },
                          },
                        },
                        [_vm._v("-")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.buySettingData.btnLoading,
                  },
                  on: { click: _vm.onSubmitSetting },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buySettingData.btnLoading },
                  on: {
                    click: function ($event) {
                      _vm.buySettingData.show = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.purchasegData.show,
            "close-on-click-modal": false,
            title: "拆分求购单配置",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.purchasegData, "show", $event)
            },
            close: function ($event) {
              _vm.purchasegData.show = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex align-items-c" },
            [
              _c("el-switch", {
                staticClass: "mr10",
                attrs: { "active-color": "#13ce66", "inactive-color": "#ccc" },
                model: {
                  value: _vm.purchasegData.hasWantBuySplitOrder,
                  callback: function ($$v) {
                    _vm.$set(_vm.purchasegData, "hasWantBuySplitOrder", $$v)
                  },
                  expression: "purchasegData.hasWantBuySplitOrder",
                },
              }),
              _vm._v(" "),
              _vm.purchasegData.hasWantBuySplitOrder
                ? [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.purchasegData.hasWantBuySplitOrderType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchasegData,
                              "hasWantBuySplitOrderType",
                              $$v
                            )
                          },
                          expression: "purchasegData.hasWantBuySplitOrderType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不限"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("指定白名单"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.purchasegData.hasWantBuySplitOrderType == 1
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "200px",
                              "margin-left": "20px",
                            },
                            attrs: {
                              multiple: "",
                              placeholder: "选择关联快照名单",
                            },
                            model: {
                              value:
                                _vm.purchasegData
                                  .hasWantBuySplitOrderSnapshotId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchasegData,
                                  "hasWantBuySplitOrderSnapshotId",
                                  $$v
                                )
                              },
                              expression:
                                "purchasegData.hasWantBuySplitOrderSnapshotId",
                            },
                          },
                          _vm._l(_vm.whitelist, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.purchasegData.btnLoading,
                  },
                  on: { click: _vm.onSubmitPurchaseg },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("Nft", {
        ref: "nft",
        attrs: { "show-select": true, "current-data": _vm.currData },
        on: { selectMessage: _vm.selectMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }