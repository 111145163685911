"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCodeVersion = addCodeVersion;
exports.addConsumptionApi = addConsumptionApi;
exports.addPlaneClass = addPlaneClass;
exports.airdropExcel = airdropExcel;
exports.airdropExport = airdropExport;
exports.appendDrawNum = appendDrawNum;
exports.appendPrize = appendPrize;
exports.assistanceExport = assistanceExport;
exports.attackDataApi = attackDataApi;
exports.attackDataDetails = attackDataDetails;
exports.attackDetailsExports = attackDetailsExports;
exports.attackExports = attackExports;
exports.batchAddBroadcastGoodsApi = batchAddBroadcastGoodsApi;
exports.batchDisableApi = batchDisableApi;
exports.batchVoid = batchVoid;
exports.broadcastDeleteApi = broadcastDeleteApi;
exports.broadcastDetailApi = broadcastDetailApi;
exports.broadcastGoodsDeleteApi = broadcastGoodsDeleteApi;
exports.broadcastGoodsImportApi = broadcastGoodsImportApi;
exports.broadcastListApi = broadcastListApi;
exports.broadcastProDeleteApi = broadcastProDeleteApi;
exports.broadcastProDetailApi = broadcastProDetailApi;
exports.broadcastProListApi = broadcastProListApi;
exports.broadcastProRemarksApi = broadcastProRemarksApi;
exports.broadcastRemarksApi = broadcastRemarksApi;
exports.canuseGame = canuseGame;
exports.changeExcelApi = changeExcelApi;
exports.changeProDisplayApi = changeProDisplayApi;
exports.changeRecommendOrCommisionApi = changeRecommendOrCommisionApi;
exports.changeStudioRoomDisplayApi = changeStudioRoomDisplayApi;
exports.changeopneMarketTypeApi = changeopneMarketTypeApi;
exports.codeDetail = codeDetail;
exports.codeExport = codeExport;
exports.combinationActivityLst = combinationActivityLst;
exports.combinationCreateProApi = combinationCreateProApi;
exports.combinationDataApi = combinationDataApi;
exports.combinationDetailApi = combinationDetailApi;
exports.combinationProDeleteApi = combinationProDeleteApi;
exports.combinationProDetailApi = combinationProDetailApi;
exports.combinationProLst = combinationProLst;
exports.combinationProSort = combinationProSort;
exports.combinationProUpdateApi = combinationProUpdateApi;
exports.combinationStatusApi = combinationStatusApi;
exports.composeExcelImport = composeExcelImport;
exports.conpounExport = conpounExport;
exports.consignConfEditApi = consignConfEditApi;
exports.consignListApi = consignListApi;
exports.consignNftListApi = consignNftListApi;
exports.consignNftRecordListApi = consignNftRecordListApi;
exports.consignStatusEditApi = consignStatusEditApi;
exports.consumptionApi = consumptionApi;
exports.continueCouponApi = continueCouponApi;
exports.couponCloneApi = couponCloneApi;
exports.couponCreateApi = couponCreateApi;
exports.couponCreatedApi = couponCreatedApi;
exports.couponDeleteApi = couponDeleteApi;
exports.couponDetailApi = couponDetailApi;
exports.couponIssueApi = couponIssueApi;
exports.couponIssueDeleteApi = couponIssueDeleteApi;
exports.couponIssueListApi = couponIssueListApi;
exports.couponIssuePushApi = couponIssuePushApi;
exports.couponListApi = couponListApi;
exports.couponSelectApi = couponSelectApi;
exports.couponSendApi = couponSendApi;
exports.couponSendLstApi = couponSendLstApi;
exports.couponUpdateApi = couponUpdateApi;
exports.couponslist = couponslist;
exports.createBroadcastApi = createBroadcastApi;
exports.createBroadcastProApi = createBroadcastProApi;
exports.createNotice = createNotice;
exports.createPlane = createPlane;
exports.delCodeVersion = delCodeVersion;
exports.delGameCanuseCollection = delGameCanuseCollection;
exports.delPlane = delPlane;
exports.deleteNotice = deleteNotice;
exports.delexchangeGoods = delexchangeGoods;
exports.disableNftApi = disableNftApi;
exports.distributorAddTeamApi = distributorAddTeamApi;
exports.distributorCreateApi = distributorCreateApi;
exports.distributorDeleteOrStartApi = distributorDeleteOrStartApi;
exports.distributorDeleteTeamApi = distributorDeleteTeamApi;
exports.distributorListApi = distributorListApi;
exports.distributorOrderListApi = distributorOrderListApi;
exports.distributorStatisticsApi = distributorStatisticsApi;
exports.distributorTeamListApi = distributorTeamListApi;
exports.distributorUpdateApi = distributorUpdateApi;
exports.downloadTemplate = downloadTemplate;
exports.editCodeVersion = editCodeVersion;
exports.editHelpInviteNumApi = editHelpInviteNumApi;
exports.editNotice = editNotice;
exports.editPlane = editPlane;
exports.editPlaneClass = editPlaneClass;
exports.exchangeGoodsCreate = exchangeGoodsCreate;
exports.exchangeGoodsList = exchangeGoodsList;
exports.exchangeGoodsRecords = exchangeGoodsRecords;
exports.exchangeTdGoodsCreate = exchangeTdGoodsCreate;
exports.exchangeTdGoodsDelete = exchangeTdGoodsDelete;
exports.exchangeTdGoodsList = exchangeTdGoodsList;
exports.exchangeTdGoodsRecord = exchangeTdGoodsRecord;
exports.exchangeTdGoodsSend = exchangeTdGoodsSend;
exports.exportReceiveListApi = exportReceiveListApi;
exports.exportTradeListApi = exportTradeListApi;
exports.exportTransactionListApi = exportTransactionListApi;
exports.fissionCreateApi = fissionCreateApi;
exports.fissionDetailApi = fissionDetailApi;
exports.fissionEditApi = fissionEditApi;
exports.fissionList = fissionList;
exports.fissionSwitchStatusApi = fissionSwitchStatusApi;
exports.fissionTaskListApi = fissionTaskListApi;
exports.getComposeInfo = getComposeInfo;
exports.getGameCanuseCollection = getGameCanuseCollection;
exports.getGameTowerConfig = getGameTowerConfig;
exports.getLuckyPrizeList = getLuckyPrizeList;
exports.getNoticeDetails = getNoticeDetails;
exports.getPlaneDetail = getPlaneDetail;
exports.getRetailUserById = getRetailUserById;
exports.getSmsSignApi = getSmsSignApi;
exports.getSmsTempApi = getSmsTempApi;
exports.getplaneList = getplaneList;
exports.hasCodeVersion = hasCodeVersion;
exports.helpCreatedApi = helpCreatedApi;
exports.helpDetailApi = helpDetailApi;
exports.helpEditApi = helpEditApi;
exports.helpListApi = helpListApi;
exports.helpRankingApi = helpRankingApi;
exports.helpTaskListApi = helpTaskListApi;
exports.importSaveScoreApi = importSaveScoreApi;
exports.infoImport = infoImport;
exports.integralConfGetInfoApi = integralConfGetInfoApi;
exports.integralConfGetInfoV2Api = integralConfGetInfoV2Api;
exports.integralConfIsOpenApi = integralConfIsOpenApi;
exports.integralConfSaveApi = integralConfSaveApi;
exports.integralConfStatisticsApi = integralConfStatisticsApi;
exports.integralConfUpdateApi = integralConfUpdateApi;
exports.integralConfUpdateV2Api = integralConfUpdateV2Api;
exports.integralDelApi = integralDelApi;
exports.integralRankingCreateApi = integralRankingCreateApi;
exports.integralRankingDataApi = integralRankingDataApi;
exports.integralRankingDelApi = integralRankingDelApi;
exports.integralRankingEditApi = integralRankingEditApi;
exports.integralRankingListApi = integralRankingListApi;
exports.integralReceivingRecordListApi = integralReceivingRecordListApi;
exports.integralSaveApi = integralSaveApi;
exports.integralTypeListApi = integralTypeListApi;
exports.integralUseRecordListApi = integralUseRecordListApi;
exports.integralUserListApi = integralUserListApi;
exports.issueApi = issueApi;
exports.luckyCreateApi = luckyCreateApi;
exports.luckyDelApi = luckyDelApi;
exports.luckyDetailApi = luckyDetailApi;
exports.luckyEditApi = luckyEditApi;
exports.luckyListApi = luckyListApi;
exports.luckyStopApi = luckyStopApi;
exports.luckySysInfoApi = luckySysInfoApi;
exports.luckyToSendApi = luckyToSendApi;
exports.marketChargeRemoveApi = marketChargeRemoveApi;
exports.marketChargeSaveApi = marketChargeSaveApi;
exports.marketChargeUpdateApi = marketChargeUpdateApi;
exports.marketConfigListApi = marketConfigListApi;
exports.marketConfigUpdateListApi = marketConfigUpdateListApi;
exports.marketGetSnapshotApi = marketGetSnapshotApi;
exports.marketHoldersListApi = marketHoldersListApi;
exports.marketInfoListApi = marketInfoListApi;
exports.marketInfoListExportApi = marketInfoListExportApi;
exports.marketInfoTakeApi = marketInfoTakeApi;
exports.marketLimitRemoveApi = marketLimitRemoveApi;
exports.marketLockApi = marketLockApi;
exports.marketNftListApi = marketNftListApi;
exports.marketPirceLimitSaveApi = marketPirceLimitSaveApi;
exports.marketPirceLimitUpdateApi = marketPirceLimitUpdateApi;
exports.marketProductBannedApi = marketProductBannedApi;
exports.marketProductIsOpenApi = marketProductIsOpenApi;
exports.marketProductListApi = marketProductListApi;
exports.marketProductTakeApi = marketProductTakeApi;
exports.marketRecordApi = marketRecordApi;
exports.marketReportProductApi = marketReportProductApi;
exports.marketReportRankApi = marketReportRankApi;
exports.marketReportTradeApi = marketReportTradeApi;
exports.marketReportViewApi = marketReportViewApi;
exports.marketUserListApi = marketUserListApi;
exports.mullock = mullock;
exports.newMemberInfo = newMemberInfo;
exports.newMemberUpdate = newMemberUpdate;
exports.newMemeberRecord = newMemeberRecord;
exports.newReport = newReport;
exports.noticeList = noticeList;
exports.planeclassifyDelete = planeclassifyDelete;
exports.planeclassifyList = planeclassifyList;
exports.playgameTDrank = playgameTDrank;
exports.pointToPointApi = pointToPointApi;
exports.productBatchDelete = productBatchDelete;
exports.productBatchIssued = productBatchIssued;
exports.productDelete = productDelete;
exports.productInfo = productInfo;
exports.productIssued = productIssued;
exports.productList = productList;
exports.productSave = productSave;
exports.productUpdate = productUpdate;
exports.productshowUpdate = productshowUpdate;
exports.receiveListApi = receiveListApi;
exports.recordExport = recordExport;
exports.redeemCodeExport = redeemCodeExport;
exports.removeCodeVersion = removeCodeVersion;
exports.retailTeamMemberExport = retailTeamMemberExport;
exports.saveServiceRateApi = saveServiceRateApi;
exports.scoreExport = scoreExport;
exports.sendCodeVersionList = sendCodeVersionList;
exports.sendExchangeGoods = sendExchangeGoods;
exports.setGameCanuseCollection = setGameCanuseCollection;
exports.setGameCanuseCollectionProperties = setGameCanuseCollectionProperties;
exports.setGameTowerConfig = setGameTowerConfig;
exports.setGameTowerSwitch = setGameTowerSwitch;
exports.shareInviteListApi = shareInviteListApi;
exports.shareListApi = shareListApi;
exports.sigEditApi = sigEditApi;
exports.signListApi = signListApi;
exports.signRecordsApi = signRecordsApi;
exports.smsReport = smsReport;
exports.smsSignCreateApi = smsSignCreateApi;
exports.smsSignDelApi = smsSignDelApi;
exports.smsSignDetailApi = smsSignDetailApi;
exports.smsSignDownloadTemplate = smsSignDownloadTemplate;
exports.smsSignListApi = smsSignListApi;
exports.smsSignSetDefaultApi = smsSignSetDefaultApi;
exports.smsSignSubmitApi = smsSignSubmitApi;
exports.smsSignUpdateApi = smsSignUpdateApi;
exports.smsTaskCreatedApi = smsTaskCreatedApi;
exports.smsTaskDelApi = smsTaskDelApi;
exports.smsTaskInfoApi = smsTaskInfoApi;
exports.smsTaskListApi = smsTaskListApi;
exports.smsTaskUpdateApi = smsTaskUpdateApi;
exports.smsTempCreatedApi = smsTempCreatedApi;
exports.smsTempDelApi = smsTempDelApi;
exports.smsTempSubmitApi = smsTempSubmitApi;
exports.smsTempUpdateApi = smsTempUpdateApi;
exports.smsTempcreateAndSubmitApi = smsTempcreateAndSubmitApi;
exports.ssrAdd = ssrAdd;
exports.ssrDelete = ssrDelete;
exports.ssrEdit = ssrEdit;
exports.ssrInfo = ssrInfo;
exports.ssrList = ssrList;
exports.stopCodeVersion = stopCodeVersion;
exports.stopCodeVersionList = stopCodeVersionList;
exports.stopCouponApi = stopCouponApi;
exports.studioEdit = studioEdit;
exports.studioProList = studioProList;
exports.synthesisAddPlayApi = synthesisAddPlayApi;
exports.synthesisCreateApi = synthesisCreateApi;
exports.synthesisDeleteApi = synthesisDeleteApi;
exports.synthesisDeleteGoodsApi = synthesisDeleteGoodsApi;
exports.synthesisDeletePlayApi = synthesisDeletePlayApi;
exports.synthesisDetailApi = synthesisDetailApi;
exports.synthesisEditApi = synthesisEditApi;
exports.synthesisEditPlayApi = synthesisEditPlayApi;
exports.synthesisListApi = synthesisListApi;
exports.synthesisPlayListApi = synthesisPlayListApi;
exports.synthesisRecordListApi = synthesisRecordListApi;
exports.synthesisRecordListExportApi = synthesisRecordListExportApi;
exports.synthesisRuleDetailApi = synthesisRuleDetailApi;
exports.synthesisSwitchStatusApi = synthesisSwitchStatusApi;
exports.synthesisUpdateGoodsApi = synthesisUpdateGoodsApi;
exports.sysProduct = sysProduct;
exports.tradeListApi = tradeListApi;
exports.transactionListApi = transactionListApi;
exports.updateBroadcastApi = updateBroadcastApi;
exports.useExport = useExport;
exports.userhasPlateHistory = userhasPlateHistory;
exports.userhasPlateList = userhasPlateList;
exports.voteBeforeOverApi = voteBeforeOverApi;
exports.voteCommitApi = voteCommitApi;
exports.voteCreateApi = voteCreateApi;
exports.voteDelApi = voteDelApi;
exports.voteDetailApi = voteDetailApi;
exports.voteEditApi = voteEditApi;
exports.voteExportApi = voteExportApi;
exports.voteListApi = voteListApi;
exports.wantBuyGetApi = wantBuyGetApi;
exports.wantBuySetApi = wantBuySetApi;
var _request = _interopRequireDefault(require("./request"));
/**
 * @description 助力有奖 -- 活动列表
 */
function helpListApi(data) {
  return _request.default.get("/activity/sys/prize/list", data);
}

/**
 * @description 助力有奖 -- 活动任务列表
 */
function helpTaskListApi(data) {
  return _request.default.get("/activity/sys/prize/getPrizeDataList", data);
}

/**
 * @description 助力有奖 -- 活动详情
 */
function helpDetailApi(id) {
  return _request.default.get("/activity/sys/prize/getDetail/".concat(id));
}
/**
 * @description 助力有奖 -- 创建活动
 */
function helpCreatedApi(data) {
  return _request.default.post("/activity/sys/prize/create", data);
}
/**
 * @description 超前合成更换名单
 */
function changeExcelApi(data) {
  return _request.default.post("/activity/sys/new/compose/append/changeExcel", data);
}
/**
 * @description 助力有奖 -- 修改活动
 */
function helpEditApi(data) {
  return _request.default.post("/activity/sys/prize/edit", data);
}
/**
 * @description 助力有奖 -- 修改活动
 */
function fissionSwitchStatusApi(data) {
  return _request.default.post("/activity/sys/prize/overOrDeleteHelp", data);
}
/**
 * @description 优惠券 -- 列表
 */
function couponListApi(data) {
  return _request.default.get("/activity/sys/coupon/list", data);
}

// 新优惠券 - 创建优惠券
function couponCreatedApi(data) {
  return _request.default.post("/activity/sys/coupon/save", data);
}

// 新优惠券 - 修改优惠券
function couponUpdateApi(data) {
  return _request.default.post("/activity/sys/coupon/update", data);
}

/**
 * @description 优惠券 -- 详情
 */
function couponDetailApi(data) {
  return _request.default.get("/activity/sys/coupon/info", data);
}
/**
 * @description 优惠券 -- 发行
 */
function couponIssueApi(id) {
  return _request.default.post("/activity/sys/coupon/postCoupon/".concat(id));
}

/**
 * @description 已发布优惠券 -- 停止发行
 */
function stopCouponApi(id) {
  return _request.default.post("/activity/sys/coupon/stopCoupon/".concat(id));
}
/**
 * @description 已发布优惠券 -- 继续发行
 */
function continueCouponApi(id) {
  return _request.default.post("/activity/sys/coupon/continueCoupon/".concat(id));
}

/**
 * @description 优惠劵 -- 删除
 */
function couponDeleteApi(id) {
  return _request.default.post("/activity/sys/coupon/delete/".concat(id));
}

/**
 * @description 优惠券 -- 领取记录
 */
function receiveListApi(data) {
  return _request.default.get("/member/sys/member/coupon/list", data);
}

/**
 * @description 优惠券模板 -- 新增表单
 */
function couponCreateApi() {
  return _request.default.get("store/coupon/create/form");
}

/**
 * @description 已发布优惠券 -- 列表
 */
function couponIssueListApi(data) {
  return _request.default.get("store/coupon/lst", data);
}
/**
 * @description 已发布优惠券 -- 添加优惠券
 */
function couponIssuePushApi() {
  return _request.default.get("store/coupon/create/form");
}
/**
 * @description 优惠券列表 -- 删除
 */
function couponIssueDeleteApi(id) {
  return _request.default.delete("store/coupon/issue/".concat(id));
}
/**
 * @description 优惠券列表 -- 复制
 */
function couponCloneApi(id) {
  return _request.default.get("store/coupon/clone/form/".concat(id));
}
/**
 * @description 优惠券列表 -- 领取记录
 */
function issueApi(data) {
  return _request.default.get("store/coupon/issue", data);
}
/**
 * @description 赠送优惠券组件列表 -- 列表
 */
function couponSelectApi(data) {
  return _request.default.get("store/coupon/select", data);
}
/**
 * @description 优惠劵 -- 发送
 */
function couponSendApi(data) {
  return _request.default.post("store/coupon/send", data);
}
/**
 * @description 优惠券发送记录 -- 列表
 */
function couponSendLstApi(data) {
  return _request.default.get("store/coupon_send/lst", data);
}
/**
 * @description 直播间 -- 创建直播间
 */
function createBroadcastApi() {
  return _request.default.get("broadcast/room/create/form");
}

/**
 * @description 直播间 -- 直播间列表
 */
function broadcastListApi(data) {
  return _request.default.get("broadcast/room/lst", data);
}
/**
 * @description 直播间 -- 直播间详情
 */
function broadcastDetailApi(id) {
  return _request.default.get("broadcast/room/detail/".concat(id));
}
/**
 * @description 直播间 -- 备注
 */
function broadcastRemarksApi(id, mark) {
  return _request.default.post("broadcast/room/mark/".concat(id), {
    mark: mark
  });
}
/**
 * @description 直播间商品 -- 创建直播间商品
 */
function createBroadcastProApi() {
  return _request.default.get("broadcast/goods/create/form");
}
/**
 * @description 直播间商品 -- 编辑
 */
function updateBroadcastApi(id) {
  return _request.default.get("broadcast/goods/update/form/".concat(id));
}
/**
 * @description 直播间商品 -- 直播间商品列表
 */
function broadcastProListApi(data) {
  return _request.default.get("broadcast/goods/lst", data);
}
/**
 * @description 直播间商品 -- 直播间商品详情
 */
function broadcastProDetailApi(id) {
  return _request.default.get("broadcast/goods/detail/".concat(id));
}
/**
 * @description 直播间商品 -- 修改显示状态（上下架）
 */
function changeProDisplayApi(id, data) {
  return _request.default.post("broadcast/goods/status/".concat(id), data);
}
/**
 * @description 直播间 -- 商品导入
 */
function broadcastGoodsImportApi(data) {
  return _request.default.post("broadcast/room/export_goods", data);
}
/**
 * @description 直播间 -- 备注
 */
function broadcastProRemarksApi(id, mark) {
  return _request.default.post("broadcast/goods/mark/".concat(id), {
    mark: mark
  });
}

/**
 * @description 直播间-- 修改显示状态（上下架）
 */
function changeStudioRoomDisplayApi(id, data) {
  return _request.default.post("broadcast/room/status/".concat(id), data);
}
/**
 * @description 直播间 -- 直播间商品
 */
function studioProList(id, data) {
  return _request.default.get("broadcast/room/goods/".concat(id), data);
}
/**
 * @description 直播间商品 -- 删除
 */
function broadcastProDeleteApi(broadcast_goods_id) {
  return _request.default.delete("broadcast/goods/delete/".concat(broadcast_goods_id));
}
/**
 * @description 直播间 -- 删除
 */
function broadcastDeleteApi(broadcast_room_id) {
  return _request.default.delete("broadcast/room/delete/".concat(broadcast_room_id));
}
/**
 * @description 直播间商品 -- 批量添加
 */
function batchAddBroadcastGoodsApi(data) {
  return _request.default.post("broadcast/goods/batch_create", data);
}
/**
 * @description 直播间已导入商品 -- 删除
 */
function broadcastGoodsDeleteApi(data) {
  return _request.default.post("broadcast/room/rm_goods", data);
}
/**
 * @description 直播间 -- 编辑
 */
function studioEdit(id) {
  return _request.default.get("broadcast/room/update/form/".concat(id));
}
/**
 * @description 拼团 -- 获取数据
 */
function combinationDataApi() {
  return _request.default.get("config/others/group_buying");
}
/**
 * @description 拼团 -- 创建拼团商品
 */
function combinationCreateProApi(data) {
  return _request.default.post("store/product/group/create", data);
}
/**
 * @description 拼团 -- 编辑拼团商品
 */
function combinationProUpdateApi(id, data) {
  return _request.default.post("store/product/group/update/".concat(id), data);
}
/**
 * @description 拼团 -- 商品列表
 */
function combinationProLst(data) {
  return _request.default.get("store/product/group/lst", data);
}
/**
 * @description 拼团 -- 商品列表（查看详情）
 */
function combinationProDetailApi(id) {
  return _request.default.get("store/product/group/detail/".concat(id));
}
/**
 * @description 拼团 -- 商品列表（删除）
 */
function combinationProDeleteApi(id) {
  return _request.default.delete("store/product/group/delete/".concat(id));
}

/**
 * @description 拼团商品列表 -- 显示状态（上下架）
 */
function combinationStatusApi(id, status) {
  return _request.default.post("store/product/group/status/".concat(id), {
    status: status
  });
}
/**
 * @description 拼团活动 -- 活动列表
 */
function combinationActivityLst(data) {
  return _request.default.get("store/product/group/buying/lst", data);
}
/**
 * @description 拼团活动 -- 查看详情
 */
function combinationDetailApi(id, data) {
  return _request.default.get("store/product/group/buying/detail/".concat(id), data);
}
/**
 * @description 拼团商品 -- 列表排序
 */
function combinationProSort(id, data) {
  return _request.default.post("/store/product/group/sort/".concat(id), data);
}
/**
 * @description 粉丝裂变 -- 活动列表
 */
function fissionList(data) {
  return _request.default.get("activity/fission/list", data);
}
/**
 * @description 粉丝裂变 -- 活动详情
 */
function fissionDetailApi(id) {
  return _request.default.get("activity/fission/info/".concat(id));
}
/**
 * @description 粉丝裂变 -- 创建活动
 */
function fissionCreateApi(data) {
  return _request.default.post("activity/fission/create", data);
}
/**
 * @description 粉丝裂变 -- 修改活动
 */
function fissionEditApi(data) {
  return _request.default.post("activity/fission/edit", data);
}
/**
 * @description 粉丝裂变 -- 活动任务列表
 */
function fissionTaskListApi(data) {
  return _request.default.get("activity/fission/taskList", data);
}

/**
 * @description 合成列表 -- 活动列表
 */
function synthesisListApi(data) {
  return _request.default.get("/activity/sys/new/compose/getComposeList", data);
}
/**
 * @description 合成 -- 活动详情
 */
function synthesisDetailApi(id) {
  return _request.default.get("/activity/sys/new/compose/getDetail/".concat(id));
}

/**
 * @description 合成玩法 -- 活动详情
 */
function synthesisRuleDetailApi(id) {
  return _request.default.get("/activity/sys/new/compose/append/getDetail/".concat(id));
  // return request.get(`activity/sys/compose/getComposeInfo/${id}`);
}

/**
 * @description 合成 -- 添加活动
 */
function synthesisCreateApi(data) {
  return _request.default.post("/activity/sys/new/compose/append/createCompose", data);
  // return request.post(`/activity/sys/new/compose/createCompose`, data);
  // return request.post(`activity/sys/compose/createCompose`, data);
}

/**
 * 合成 - 导入模板
 */
function composeExcelImport(data) {
  return _request.default.post("/activity/sys/new/compose/excelImport", data);
}

/**
 * @description 合成 -- 编辑活动
 */
function synthesisEditApi(data) {
  return _request.default.post("/activity/sys/new/compose/append/editCompose", data);
  // return request.post(`/activity/sys/new/compose/editCompose`, data);
}
/**
 * @description 合成 -- 修改活动展示状态
 */
function synthesisSwitchStatusApi(id, data) {
  return _request.default.post("activity/synthetic/album/switchShowStatus/".concat(id), data);
}
/**
 * @description 合成 -- 删除活动
 */
function synthesisDeleteApi(id) {
  return _request.default.post("/activity/sys/new/compose/delCompose/".concat(id));
}
/**
 * @description 合成 -- 合成记录列表
 */
function synthesisRecordListApi(data) {
  return _request.default.get("/activity/sys/new/compose/getRecords", data);
}

/**
 * @description 合成导出 -- 合成记录列表
 */
function synthesisRecordListExportApi(data) {
  return _request.default.get("activity/sys/compose/composeNftExport", data);
}

/**
 * @description 合成 -- 玩法列表
 */
function synthesisPlayListApi(data) {
  return _request.default.get("activity/synthetic/lst", data);
}
/**
 * @description 合成 -- 添加活动玩法列表
 */
function synthesisAddPlayApi(data) {
  return _request.default.post("activity/synthetic/create", data);
}
/**
 * @description 合成 -- 编辑活动玩法列表
 */
function synthesisEditPlayApi(id, data) {
  return _request.default.post("activity/synthetic/update/".concat(id), data);
}
/**
 * @description 合成 -- 删除活动玩法
 */
function synthesisDeletePlayApi(id) {
  return _request.default.post("activity/synthetic/delete/".concat(id));
}
/**
 * @description 合成 -- 修改合成藏品
 */
function synthesisUpdateGoodsApi(id, data) {
  return _request.default.post("activity/synthetic/updateProduct/".concat(id), data);
}
/**
 * @description 合成 -- 删除合成材料
 */
function synthesisDeleteGoodsApi(productId) {
  return _request.default.post("/activity/sys/compose/delCompose/{id}".concat(productId));
}

/**
 * @description 抽奖 -- 活动列表
 */
function luckyListApi(data) {
  return _request.default.get("/activity/sys/lucky/getlist", data);
}

/**
 * @description 抽奖 -- 活动详情
 */
function luckyDetailApi(id) {
  return _request.default.get("/activity/sys/lucky/info/".concat(id));
}

/**
 * @description 抽奖 -- 参与记录
 */
function luckySysInfoApi(data) {
  return _request.default.get("/activity/sys/lucky/getSysInfo", data);
}

/**
 * @description 抽奖 -- 创建活动
 */
function luckyCreateApi(data) {
  return _request.default.post("/activity/sys/lucky/create", data);
}
/**
 * @description 抽奖 -- 修改活动
 */
function luckyEditApi(data) {
  return _request.default.post("/activity/sys/lucky/edit", data);
}
/**
 * @description 抽奖 -- 删除活动
 */
function luckyDelApi(id) {
  return _request.default.delete("/activity/sys/lucky/del/".concat(id));
}
/**
 * @description 抽奖 -- 结束活动
 */
function luckyStopApi(id) {
  return _request.default.put("/activity/sys/lucky/over/".concat(id));
}
/**
 * @description 抽奖 -- 发货
 */
function luckyToSendApi(data) {
  return _request.default.post("/activity/sys/lucky/isToSend", data);
}

/**
 * @description 投票 -- 列表
 */
function voteListApi(data) {
  return _request.default.get("/activity/sys/vote/list", data);
}

/**
 * @description 投票 -- 详情
 */
function voteDetailApi(id) {
  return _request.default.get("/activity/sys/vote/".concat(id));
}

/**
 * @description 投票 -- 创建
 */
function voteCreateApi(data) {
  return _request.default.post("/activity/sys/vote", data);
}

/**
 * @description 投票 -- 修改
 */
function voteEditApi(data) {
  return _request.default.put("/activity/sys/vote", data);
}

/**
 * @description 投票 -- 确认
 */
function voteCommitApi(id) {
  return _request.default.post("/activity/sys/vote/commit/".concat(id));
}

/**
 * @description 投票 -- 提前结束
 */
function voteBeforeOverApi(id) {
  return _request.default.post("/activity/sys/vote/beforeOver/".concat(id));
}

/**
 * @description 投票 -- 删除
 */
function voteDelApi(id) {
  return _request.default.delete("/activity/sys/vote/".concat(id));
}

/**
 * @description 投票 -- 导出投票信息
 */
function voteExportApi(data) {
  return _request.default.get("/activity/sys/vote/export", data);
}

/**
 * @description 签到 -- 配置列表
 */
function signListApi(data) {
  return _request.default.get("/activity/sys/sign/list", data);
}

/**
 * @description 签到 -- 修改配置
 */
function sigEditApi(data) {
  return _request.default.post("/activity/sys/sign/edit", data);
}

/**
 * @description 签到 -- 领取记录
 */
function signRecordsApi(data) {
  return _request.default.get("/activity/sys/sign/getPrizeRecords", data);
}

/**
 * @description 群发短信 - 详情
 */
function smsTaskInfoApi(data) {
  return _request.default.get("system/sms/taskInfo", data);
}
/**
 * @description 群发短信 - 列表
 */
function smsTaskListApi(data) {
  return _request.default.get("resource/sms/list", data);
}
/**
 * @description 群发短信 - 删除
 */
function smsTaskDelApi(id) {
  return _request.default.delete("resource/sms/delete/".concat(id));
}
/**
 * @description 群发短信 - 创建
 */
function smsTaskCreatedApi(data) {
  return _request.default.post("resource/sms/batchSend", data);
}
/**
 * @description 群发短信 - 创建
 */
function smsTaskUpdateApi(data) {
  return _request.default.put("resource/sms/update", data);
}
/**
 * @description 群发短信 - 模板 签名
 */
function getSmsSignApi(data) {
  return _request.default.get("system/sms/temp/getSmsSign", data);
}
/**
 * @description 群发短信 - 模板列表
 */
function getSmsTempApi(data) {
  return _request.default.get("resource/sms/template/list", data);
}
/**
 * @description 群发短信 - 创建模板
 */
function smsTempCreatedApi(data) {
  return _request.default.post("resource/sms/template", data);
}
/**
 * @description 群发短信 - 保存并审核模板
 */
function smsTempcreateAndSubmitApi(data) {
  return _request.default.post("system/sms/temp/createAndSubmit", data);
}
/**
 * @description 群发短信 - 提交审核
 */
function smsTempSubmitApi(id) {
  return _request.default.post("resource/sms/template/".concat(id));
}
/**
 * @description 群发短信 - 删除模板
 */
function smsTempDelApi(id) {
  return _request.default.delete("resource/sms/template/".concat(id));
}
/**
 * @description 群发短信 - 修改模板
 */
function smsTempUpdateApi(data) {
  return _request.default.put("resource/sms/template", data);
}
/**
 * @description 群发短信 - 签名列表
 */
function smsSignListApi(data) {
  return _request.default.get("resource/sms/sign/list", data);
}
/**
 * @description 群发短信 - 签名详情
 */
function smsSignDetailApi(data) {
  return _request.default.get("system/sms/sign/detail", data);
}
/**
 * @description 群发短信 - 创建签名
 */
function smsSignCreateApi(data) {
  return _request.default.post("resource/sms/sign", data);
}
/**
 * @description 群发短信 - 修改签名
 */
function smsSignUpdateApi(data) {
  return _request.default.put("resource/sms/sign", data);
}
/**
 * @description 群发短信 - 签名提交审核
 */
function smsSignSubmitApi(id) {
  return _request.default.post("resource/sms/sign/".concat(id));
}
/**
 * @description 群发短信 - 删除签名
 */
function smsSignDelApi(id) {
  return _request.default.delete("resource/sms/sign/".concat(id));
}
/**
 * @description 群发短信 - 删除签名
 */
function smsSignSetDefaultApi(data) {
  return _request.default.post("system/sms/sign/setDefault", data);
}

/**
 * @description 寄售 - 藏品/系列列表
 */
function consignListApi(data) {
  return _request.default.get("consign/conf/list", data);
}
/**
 * @description 寄售 - 售卖列表
 */
function consignNftListApi(data) {
  return _request.default.get("consign/conf/nftList", data);
}
/**
 * @description 寄售 - 售卖列表 - 全部列表
 */
function marketNftListApi(data) {
  return _request.default.get("consign/nft/list", data);
}
/**
 * @description 寄售 - 售卖日志列表
 */
function consignNftRecordListApi(data) {
  return _request.default.get("consign/log/list", data);
}
/**
 * @description 寄售 - 修改限价规则
 */
function consignConfEditApi(data) {
  return _request.default.post("consign/conf/save", data);
}
/**
 * @description 寄售 - 开启/关闭寄售
 */
function consignStatusEditApi(data) {
  return _request.default.post("consign/conf/setStatus", data);
}
/**
 * @description 寄售 - 交易列表
 */
function tradeListApi(data) {
  return _request.default.get("consign/log/tradeList", data);
}

// 下架所有寄售nft
function batchDisableApi(data) {
  return _request.default.post("consign/conf/disableNft", data);
}

// 下架单个寄售nft
function disableNftApi(data) {
  return _request.default.post("consign/conf/disable", data);
}

// 修改服务费率
function saveServiceRateApi(data) {
  return _request.default.post("consign/conf/saveServiceRate", data);
}

// 导出二手交易记录
function exportTradeListApi(data) {
  return _request.default.get("consign/log/exportTradeList", data);
}

// 分销员列表
function distributorListApi(data) {
  return _request.default.get("member/sys/retail/list", data);
}

// 分销员创建
function distributorCreateApi(data) {
  return _request.default.post("member/sys/retail/addRetail", data);
}

// 分销员编辑
function distributorUpdateApi(data) {
  return _request.default.post("member/sys/retail/editRetail", data);
}

// 根据id查询分销员的详情
function getRetailUserById(data) {
  return _request.default.get("/member/sys/retail/getRetailUserById", data);
}

// 分销员更改状态或删除
function distributorDeleteOrStartApi(data) {
  return _request.default.post("member/sys/retail/deleteOrStart", data);
}

// 分销员添加团队
function distributorAddTeamApi(data) {
  return _request.default.post("member/sys/retail/addTeamMember", data);
}

// 分销员删除团队
function distributorDeleteTeamApi(data) {
  return _request.default.post("member/sys/retail/removeMember", data);
}

// 分销员成员列表
function distributorTeamListApi(data) {
  return _request.default.get("member/sys/retail/teamMemberList", data);
}

// 分销员订单列表
function distributorOrderListApi(data) {
  return _request.default.get("member/sys/retail/retailOrderList", data);
}

// 分销员统计接口
function distributorStatisticsApi() {
  return _request.default.get("member/app/retail/retailAppCount");
}

/**
 * @description 优惠券 -- 领取记录 - 导出
 */
function exportReceiveListApi(data) {
  return _request.default.post("coupon/exportReceiveList", data);
}
/**
 * @description 优惠券 -- 使用记录
 */
function transactionListApi(data) {
  return _request.default.post("coupon/transactionList", data);
}
/**
 * @description 优惠券 -- 使用记录 - 导出
 */
function exportTransactionListApi(data) {
  return _request.default.post("coupon/exportTransactionList", data);
}
/**
 * @description 积分 - 查看配置详情
 */
function integralConfGetInfoApi() {
  return _request.default.get("activity/sys/socre-rule/getAll");
}
/**
 * @description 积分 - 查看配置详情 - v2
 */
function integralConfGetInfoV2Api() {
  return _request.default.get("/activity/sys/socre-rule/v2/getAll");
}
/**
 * @description 积分 - 统计
 */
function integralConfStatisticsApi(data) {
  return _request.default.get("activity/sys/score/total", data);
}
/**
 * @description 积分 - 保存配置详情
 */
function integralConfSaveApi(data) {
  return _request.default.post("integralConf/save", data);
}

/**
 * @description 积分 - 保存配置详情
 */
function integralConfIsOpenApi(data) {
  return _request.default.post("activity/sys/socre-rule/isOpen", data);
}

/**
 * @description 积分 - 修改积分规则
 */
function integralConfUpdateV2Api(data) {
  return _request.default.post("/activity/sys/socre-rule/v2/update", data);
}

/**
 * @description 积分 - 修改积分规则
 */
function integralConfUpdateApi(data) {
  return _request.default.post("activity/sys/socre-rule/update", data);
}

/**
 * @description 积分 - 使用记录
 */
function integralUseRecordListApi(data) {
  return _request.default.post("UserIntegralChangeRecord/getList", data);
}
/**
 * @description 积分 - 发放记录
 */
function integralReceivingRecordListApi(data) {
  return _request.default.get("activity/sys/score-details/page", data);
}
/**
 * @description 积分 - 积分明细
 */
function integralUserListApi(data) {
  return _request.default.get("/activity/sys/score/get", data);
}
/**
 * @description 积分 - 排行榜列表
 */
function integralRankingListApi(data) {
  return _request.default.get("activity/sys/rank/list", data);
}
/**
 * @description 积分 - 排行榜统计数据
 */
function integralRankingDataApi(id, data) {
  return _request.default.get("/activity/sys/score/v2/rank/".concat(id), data);
}
/**
 * @description 积分 - 创建排行榜统计
 */
function integralRankingCreateApi(data) {
  return _request.default.post("activity/sys/rank/save", data);
}
/**
 * @description 积分 - 修改排行榜统计
 */
function integralRankingEditApi(data) {
  return _request.default.post("activity/sys/rank/update", data);
}
/**
 * @description 积分 - 删除排行榜统计
 */
function integralRankingDelApi(id) {
  return _request.default.post("activity/sys/rank/delete/".concat(id));
}
/**
 * @description 积分 - 类型列表
 */
function integralTypeListApi(id) {
  return _request.default.get("/activity/sys/socre/type/list");
}
/**
 * @description 积分 - 修改类型
 */
function integralSaveApi(data) {
  return _request.default.post("/activity/sys/socre/type/saveOrUpdate", data);
}
/**
 * @description 积分 - 删除类型
 */
function integralDelApi(id) {
  return _request.default.delete("/activity/sys/socre/type/".concat(id));
}
/**
 * @description 分享员 -- 分享员列表
 */
function shareListApi(data) {
  return _request.default.get("user/getSpradUserList", data);
}
/**
 * @description 分享员 -- 邀请列表
 */
function shareInviteListApi(data) {
  return _request.default.get("user/getOneLevelUserList", data);
}

/**
 * @description 活动 -- 助力排行
 */
function helpRankingApi(data) {
  return _request.default.get("activity/help/helpRanking", data);
}

/**
 * @description 活动 -- 修改助力
 */
function editHelpInviteNumApi(data) {
  return _request.default.post("activity/help/editHelpInviteNum", data);
}

/**
 * @description 寄售明细列表
 */
function marketInfoListApi(data) {
  return _request.default.get("market/sys/market-info/list", data);
}

/**
 * @description 导出寄售明细列表
 */
function marketInfoListExportApi(data) {
  return _request.default.get("market/sys/market-info/export", data);
}

/**
 * @description 寄售明细列表 - 下架
 */
function marketInfoTakeApi(data) {
  return _request.default.post("market/sys/market-info/take", data);
}

/**
 * @description 寄售明细列表 - 锁单
 */
function marketLockApi(id) {
  return _request.default.post("market/sys/market-info/lock/".concat(id));
}

/**
 * @description 查询某商品的寄售记录
 */
function marketRecordApi(id, data) {
  return _request.default.get("market/sys/market-record/page/".concat(id), data);
}

/**
 * @description 寄售管理列表
 */
function marketProductListApi(data) {
  return _request.default.get("market/sys/market-product/newlist", data);
}

/**
 * @description 寄售管理列表 - 修改服务费率
 */
function marketChargeUpdateApi(id, data) {
  return _request.default.post("market/sys/charge/update/".concat(id), data);
}

/**
 * @description 寄售管理列表 - 新增服务费率
 */
function marketChargeSaveApi(data) {
  return _request.default.post("market/sys/charge/save", data);
}

/**
 * @description 寄售管理列表 - 删除服务费率规则
 */
function marketChargeRemoveApi(id) {
  return _request.default.post("market/sys/charge/remove/".concat(id));
}

/**
 * @description 寄售管理列表 - 快照名单下拉框接口
 */
function marketGetSnapshotApi() {
  return _request.default.get("activity/sys/snapshot/getSnapshot");
}

/**
 * @description 寄售管理列表 - 新增限价设置
 */
function marketPirceLimitSaveApi(data) {
  return _request.default.post("market/sys/pirce-limit/save", data);
}

/**
 * @description 寄售管理列表 - 修改限价设置
 */
function marketPirceLimitUpdateApi(id, data) {
  return _request.default.post("market/sys/pirce-limit/update/".concat(id), data);
}

/**
 * @description 寄售管理列表 - 下架所有藏品
 */
function marketProductTakeApi(productId) {
  return _request.default.post("market/sys/market-product/take/".concat(productId));
}

/**
 * @description 寄售管理列表 - 设置禁售
 */
function marketProductBannedApi(productId, data) {
  return _request.default.post("market/sys/market-product/banned/".concat(productId), data);
}

/**
 * @description 寄售管理列表 - 关闭/开启寄售
 */
function marketProductIsOpenApi(productId, data) {
  return _request.default.post("market/sys/market-product/isOpen/".concat(productId), data);
}

/**
 * @description 寄售管理列表 - 删除限价设置
 */
function marketLimitRemoveApi(id) {
  return _request.default.post("market/sys/pirce-limit/remove/".concat(id));
}

/**
 * @description 寄售用户列表
 */
function marketUserListApi(data) {
  return _request.default.get("market/sys/market-product/market-user-list", data);
}

/**
 * @description 持仓用户列表
 */
function marketHoldersListApi(data) {
  return _request.default.get("product/sys/product/holders", data);
}

/**
 * @description 获取寄售所有配置
 */
function marketConfigListApi() {
  return _request.default.get("market/sys/market-config/list");
}

/**
 * @description 修改寄售配置项
 */
function marketConfigUpdateListApi(data) {
  return _request.default.post("market/sys/market-config/update", data);
}

/**
 * @description 统计 - 市场概况
 */
function marketReportViewApi(data) {
  return _request.default.get("market/sys/report/view", data);
}

/**
 * @description 统计 - 交易趋势
 */
function marketReportTradeApi(data) {
  return _request.default.get("market/sys/report/trade", data);
}

/**
 * @description 统计 - 商品趋势
 */
function marketReportProductApi(data) {
  return _request.default.get("market/sys/report/product", data);
}

/**
 * @description 统计 - 商品排行
 */
function marketReportRankApi(data) {
  return _request.default.get("market/sys/report/rank", data);
}

/**
 * 兑换码 - 列表
 */
function sendCodeVersionList(data) {
  return _request.default.get("activity/sys/redeem/code/list", data);
}

/**
 * 兑换码 - 新增
 */
function addCodeVersion(data) {
  return _request.default.post("activity/sys/redeem/code/save", data);
}
/**
 * 兑换码 - 删除
 */
function removeCodeVersion(data) {
  return _request.default.delete("activity/sys/redeem/code/".concat(data.id));
}

/**
 * 兑换码 - 结束兑换
 */
function stopCodeVersion(data) {
  return _request.default.put("activity/sys/redeem/code/over/".concat(data.id));
}
/**
 * 兑换码 - 列表
 */
function stopCodeVersionList(data) {
  return _request.default.get("activity/sys/redeem/code/detail/list", data);
}
/**
 * 兑换码 - 编辑
 */
function editCodeVersion(data) {
  return _request.default.put("activity/sys/redeem/code/update", data);
}
/**
 * 兑换码 - 作废
 */
function delCodeVersion(data) {
  return _request.default.put("activity/sys/redeem/code/detail/void/".concat(data.id));
}
/**
 * d兑换码 - 已兑换
 */
function hasCodeVersion(data) {
  return _request.default.get("activity/sys/redeem/code/record/list", data);
}

/**
 * 兑换码 导入
 */
function infoImport(data) {
  return _request.default.post("activity/sys/redeem/code/import", data);
}

/**
 * 兑换码详情 /activity/sys/redeem/code/info/{id}
 */
function codeDetail(data) {
  return _request.default.get("/activity/sys/redeem/code/info/".concat(data.id), data);
}

/**
 * 批量废除兑换码
 */
function batchVoid(data) {
  return _request.default.put("/activity/sys/redeem/code/detail/batchVoid", data);
}

/**
 * 获取新人专享 /activity/sys/new/member/info
 */
function newMemberInfo(data) {
  return _request.default.get("activity/sys/new/member/info", data);
}

/**
 * 修改新人专享 /activity/sys/new/member/update
 */
function newMemberUpdate(data) {
  return _request.default.put("activity/sys/new/member/update", data);
}

/**
 * 分页显示新人专享列表 /activity/sys/new/member/record/list
 */
function newMemeberRecord(data) {
  return _request.default.get("activity/sys/new/member/record/list", data);
}

/**
 * 新人专享导出
 */
function newReport(data) {
  return _request.default.get("activity/sys/new/member/record/export", data);
}

/**
 * 优惠券 /activity/sys/coupon/list
 */
function couponslist(data) {
  return _request.default.get("activity/sys/coupon/list", data);
}

/**
 *   修改商品-实物商品 /product/sys/product/update
 */
function productUpdate(data) {
  return _request.default.put("product/sys/product/update", data);
}

/**
 * 批量操作检查 /product/sys/product
 */
function sysProduct(data) {
  return _request.default.post("product/sys/product", data);
}

/**
 * 上下架商品-实物商品 /product/sys/product/showUpdate
 */
function productshowUpdate(data) {
  return _request.default.post("product/sys/product/showUpdate", data);
}

/**
 * 新增商品-实物商品 //product/sys/product/save
 */
function productSave(data) {
  return _request.default.post("product/sys/product/save", data);
}

/**
 * 发行商品-实物商品 /product/sys/product/issued/{id}
 */
function productIssued(data) {
  return _request.default.post("product/sys/product/issued/".concat(data), data);
}

/**
 * 批量发行商品-实物商品 /product/sys/product/batchIssued
 */
function productBatchIssued(data) {
  return _request.default.post("product/sys/product/batchIssued", data);
}

/**
 * 分页显示商品-实物商品 /product/sys/product/list
 */
function productList(data) {
  return _request.default.get("product/sys/product/list", data);
}

/**
 * 获取实物商品详情 /product/sys/product/info
 */
function productInfo(data) {
  return _request.default.get("product/sys/product/info", data);
}

/**
 * 删除商品-实物商品 /product/sys/product/delete/{id}
 */
function productDelete(data) {
  return _request.default.delete("product/sys/product/delete/".concat(data), data);
}
/**
 * 批量删除商品-实物商品  /product/sys/product/batchDelete
 */
function productBatchDelete(data) {
  return _request.default.delete("product/sys/product/batchDelete", data);
}
/**
 * 导出兑换码 /activity/sys/redeem/code/export
 */
function codeExport(data) {
  return _request.default.get("activity/sys/redeem/code/export", data);
}

/**
 * 分销员导出 /member/sys/retail/retailUserExport
 */
function redeemCodeExport(data) {
  return _request.default.get("member/sys/retail/retailUserExport", data);
}

/**
 * 团队分销员
 */
function retailTeamMemberExport(data) {
  return _request.default.get("member/sys/retail/retailTeamMemberExport", data);
}

/**
 * 短信群发 上传文件 /resource/sms/import
 */
function smsReport(data) {
  return _request.default.post("resource/sms/import", data);
}

/**
 * 合成玩法 activity/sys/compose/getComposeInfo/{id}
 */
function getComposeInfo(data) {
  return _request.default.get("/activity/sys/new/compose/getComposeInfo/".concat(data.id), data);
}

/**
 * 助力拉新导出 /activity/sys/prize/assistanceExport
 */
function assistanceExport(data) {
  return _request.default.get("activity/sys/prize/assistanceExport/".concat(data.id), data);
}

/**
 * 优惠券使用记录导出 /member/sys/member/coupon/use/export
 */
function useExport(data) {
  return _request.default.get("member/sys/member/coupon/use/export", data);
}

/**
 * 优惠券领取记录导出 member/sys/member/coupon/export
 */
function conpounExport(data) {
  return _request.default.get("member/sys/member/coupon/export", data);
}

/**
 * 兑换记录导出
 */
function recordExport(data) {
  return _request.default.get("activity/sys/redeem/code/record/export", data);
}
/**
 * 创建站内群发
 */
function createNotice(data) {
  return _request.default.post("activity/sys/bathsend/message/add", data);
}
/**
 * 编辑站内群发
 */
function editNotice(data) {
  return _request.default.put("activity/sys/bathsend/message/edit", data);
}
/**
 * 站内群发列表
 */
function noticeList(data) {
  return _request.default.get("activity/sys/bathsend/message/list", data);
}
/**
 * 删除站内群发
 */
function deleteNotice(id) {
  return _request.default.delete("activity/sys/bathsend/message/del/".concat(id));
}
/**
 * 根据id获取站内消息详情
 */
function getNoticeDetails(id) {
  return _request.default.get("activity/sys/bathsend/message/detailInfo/".concat(id));
}
/**
 * 稀有度列表
 */
function ssrList(data) {
  return _request.default.get("product/sys/box/rare/list", data);
}
/**
 * 稀有度添加
 */
function ssrAdd(data) {
  return _request.default.post("product/sys/box/rare/save", data);
}
/**
 * 稀有度编辑
 */
function ssrEdit(data) {
  return _request.default.put("product/sys/box/rare/update", data);
}
/**
 * 稀有度详情
 */
function ssrInfo(data) {
  return _request.default.get("product/sys/box/rare/info", data);
}
/**
 * 稀有度删除
 */
function ssrDelete(id) {
  return _request.default.delete("product/sys/box/rare/delete/".concat(id));
}
/**
 * 开启关闭寄售类型
 */
function changeopneMarketTypeApi(id, data) {
  return _request.default.post("market/sys/market-product/type/isOpen/".concat(id), data);
}
/**
 * 修改推荐关系，分佣比例
 */
function changeRecommendOrCommisionApi(data) {
  return _request.default.put("market/sys/market-product/update", data);
}
/**
 * @description 小游戏 -- 创建战机
 */
function createPlane(data) {
  return _request.default.post("activity/sys/fighter/mining/add", data);
}
/**
 * @description 小游戏 -- 战机详情
 */
function getPlaneDetail(id, data) {
  return _request.default.get("activity/sys/fighter/mining/info/".concat(id), data);
}
/**
 * @description 小游戏 -- 战机列表
 */
function getplaneList(data) {
  return _request.default.get("activity/sys/fighter/mining/list", data);
}
/**
 * @description 小游戏 -- 修改
 */
function editPlane(data) {
  return _request.default.post("activity/sys/fighter/mining/edit", data);
}
/**
 * @description 小游戏 -- 删除
 */
function delPlane(id, data) {
  return _request.default.delete("activity/sys/fighter/mining/del/".concat(id), data);
}
/**
 * @description 小游戏 -- 用户拥有战机的数量
 */
function userhasPlateList(data) {
  return _request.default.get("activity/sys/fighter/mining/fighterUserRecords", data);
}
/**
 * @description 小游戏 -- 用户拥有战机的历史记录
 */
function userhasPlateHistory(data) {
  return _request.default.get("activity/sys/fighter/mining/userRecordsPrize", data);
}

/**
 * 小游戏 - 查询商户是否可以游戏
 */
function canuseGame(data) {
  return _request.default.post("game/conf", data);
}
/**
 * 兑换商城 - 兑换商城列表
 */
function exchangeGoodsList(data) {
  return _request.default.get("activity/sys/fighter/mining/exchangeRulesList", data);
}
/**
 * 兑换商城 - 兑换商城历史记录
 */
function exchangeGoodsRecords(data) {
  return _request.default.get("activity/sys/fighter/mining/exchangeRecords", data);
}
/**
 * 兑换商城 - 创建兑换商品
 */
function exchangeGoodsCreate(data) {
  return _request.default.post("activity/sys/fighter/mining/addExchangeRules", data);
}
/**
 * 兑换商城 - 删除兑换商品
 */
function delexchangeGoods(id, data) {
  return _request.default.delete("activity/sys/fighter/mining/delExchangeRules/".concat(id), data);
}
/**
 * 兑换商城 - 兑换商品发货
 */
function sendExchangeGoods(data) {
  return _request.default.post("activity/sys/tower/defense/redeem/record/ship", data);
}

/**
 * 塔防游戏 - 获取游戏配置详情
 */
function getGameTowerConfig() {
  return _request.default.get("activity/sys/tower/defense/info");
}
/**
 * 塔防游戏 - 修改游戏进行状态
 */
function setGameTowerSwitch(data) {
  return _request.default.post("game/towerDefense/switch", data);
}
/**
 * 塔防游戏 - 修改游戏配置详情
 */
function setGameTowerConfig(data) {
  return _request.default.put("activity/sys/tower/defense/update", data);
}
/**
 * 塔防游戏 - 获取可投放藏品列表
 */
function getGameCanuseCollection(data) {
  return _request.default.get("activity/sys/tower/defense/product/getList", data);
}
/**
 * 塔防游戏 - 创建可投放藏品列表
 */
function setGameCanuseCollection(id, data) {
  return _request.default.post("activity/sys/tower/defense/product/".concat(id), data);
}
/**
 * 塔防游戏 - 删除可投放藏品
 */
function delGameCanuseCollection(id, data) {
  return _request.default.delete("activity/sys/tower/defense/product/".concat(id), data);
}
/**
 * 塔防游戏 - 创建可投放藏品概率
 */
function setGameCanuseCollectionProperties(data) {
  return _request.default.put("activity/sys/tower/defense/product", data);
}
/**
 * 塔防游戏 - 进攻数据
 */
function attackDataApi(data) {
  return _request.default.get("activity/sys/tower/defense/attack/getList", data);
}
/**
 * 塔防游戏 - 导出进攻数据
 */
function attackExports(data) {
  return _request.default.post("game/rounds/exportList", data);
}
/**
 * 塔防游戏 - 进攻数据明细
 */
function attackDataDetails(data) {
  return _request.default.get("activity/sys/tower/defense/attack/getScoreList", data);
}
/**
 * 塔防游戏 - 导出进攻数据明细
 */
function attackDetailsExports(data) {
  return _request.default.post("game/rounds/exportSettlementList", data);
}
/**
 * 塔防游戏 - 塔防用户积分
 */
function playgameTDrank(data) {
  return _request.default.get("activity/sys/tower/defense/member/score/getList", data);
}

/**
 * 塔防游戏 - 创建塔防兑换商品
 */
function exchangeTdGoodsCreate(data) {
  return _request.default.post("activity/sys/tower/defense/redeem/shopping/save", data);
}
/**
 * 塔防游戏 - 获取塔防兑换商品列表
 */
function exchangeTdGoodsList(data) {
  return _request.default.get("activity/sys/tower/defense/redeem/shopping/getList", data);
}
/**
 * 塔防游戏 - 删除塔防兑换商品
 */
function exchangeTdGoodsDelete(id, data) {
  return _request.default.delete("activity/sys/tower/defense/redeem/shopping/".concat(id), data);
}
/**
 * 塔防游戏 - 兑换商品发货
 */
function exchangeTdGoodsSend(data) {
  return _request.default.post("activity/sys/tower/defense/redeem/record/ship", data);
}
/**
 * 塔防游戏 - 兑换商品兑换记录
 */
function exchangeTdGoodsRecord(data) {
  return _request.default.get("activity/sys/tower/defense/redeem/record/getList", data);
}
/**
 * 抽奖 - 下载模板
 */
function downloadTemplate(data) {
  return _request.default.get("activity/sys/tower/defense/redeem/record/getList", data);
}
/**
 * 短信 - 下载模板
 */
function smsSignDownloadTemplate(data) {
  return _request.default.get("/resource/sms/sign/templateDownload", data);
}
/**
 * 抽奖 - 导入模板
 */
function airdropExcel(data) {
  return _request.default.post("/activity/sys/lucky/excelImport", data);
}
/**
 * 抽奖 - 增加抽奖次数
 */
function appendDrawNum(data) {
  return _request.default.post("/activity/sys/lucky/appendDrawNum", data);
}
/**
 * 抽奖 - 增加抽奖次数
 */
function getLuckyPrizeList(id) {
  return _request.default.get("/activity/sys/lucky/getLuckyPrizeList/".concat(id));
}

/**
 * 抽奖 - 追加奖品
 */
function appendPrize(data) {
  return _request.default.post("/activity/sys/lucky/appendPrize", data);
}

/**
 * 抽奖 - 导出
 */
function airdropExport(data) {
  return _request.default.get("/activity/sys/lucky/airdropExport", data);
}
// 添加战机分类
function addPlaneClass(data) {
  return _request.default.post("/activity/sys/fighter/mining/createClassify", data);
}
// 编辑分类
function editPlaneClass(data) {
  return _request.default.put("/activity/sys/fighter/mining/classifyEdit", data);
}
// 分类列表
function planeclassifyList(data) {
  return _request.default.get("/activity/sys/fighter/mining/getListClassify", data);
}
// 删除分类
function planeclassifyDelete(id) {
  return _request.default.delete("/activity/sys/fighter/mining/delClassify/".concat(id), data);
}
// 删除分类
function importSaveScoreApi(data) {
  return _request.default.post("/activity/sys/fighter/mining/importSaveScore", data);
}

//导出积分明细列表
function scoreExport(data) {
  return _request.default.get("/activity/sys/score/export", data);
}

//批量锁单
function mullock(data) {
  return _request.default.post("/market/sys/market-info/mullock ", data);
}

//批量转移
function pointToPointApi(data) {
  return _request.default.post("/product/sys/product/pointToPoint", data);
}

//获取求购 买入配置详情
function consumptionApi(marketProductId, type) {
  return _request.default.get("/market/sys/market-product/consumption/".concat(marketProductId, "/").concat(type));
}
//求购 买入配置保存
function addConsumptionApi(data) {
  return _request.default.post("/market/sys/market-product/consumption", data);
}

//获取求购 买入配置详情
function wantBuyGetApi(id) {
  return _request.default.get("/market/sys/market-product/wantBuy/split/info/".concat(id));
}
//拆分求购单设置
function wantBuySetApi(data) {
  return _request.default.post("/market/sys/market-product/wantBuy/split/set", data);
}