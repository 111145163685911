var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container blindDetails",
      staticStyle: { position: "relative", "padding-bottom": "76px" },
    },
    [
      _c("el-button", { on: { click: _vm.backAction } }, [_vm._v("返回")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "padding20 bg-white borderRadius6" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm mt20",
              attrs: {
                model: _vm.blindDetail,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "w120 fontW400 ml40 ft13",
                  staticStyle: { color: "#606266", display: "inline-block" },
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                  _vm._v("盲盒封面："),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "image")
                        },
                      },
                    },
                    [
                      _vm.blindDetail.image
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("image")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.blindDetail.image
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.blindDetail.image + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft13" },
                    [
                      _vm._v("\n          列表页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "列表页的展示图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "item-align-center mb20 ml10",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-img-view item-flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.modalPicTap(1, "infoImage")
                        },
                      },
                    },
                    [
                      _vm.blindDetail.infoImage
                        ? _c(
                            "div",
                            {
                              staticClass: "del-upload-img",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.delImg("infoImage")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.blindDetail.infoImage
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.blindDetail.infoImage + "!120a" },
                          })
                        : _c("img", {
                            staticClass: "upload-icon",
                            attrs: {
                              src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ft13" },
                    [
                      _vm._v("\n          详情页\n          "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "详情页展示图，如不传则使用列表页图",
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ft13 ml20",
                  staticStyle: { display: "inline-block", color: "#606266" },
                },
                [
                  _vm._v(
                    "\n        (图片格式支持：JPG、JPEG、PNG、GIF；文件大小≤15M；图片比例建议1：1)"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用场景：", prop: "scenes" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.editFlag },
                      on: { change: _vm.fileTypeChange },
                      model: {
                        value: _vm.blindDetail.scenes,
                        callback: function ($$v) {
                          _vm.$set(_vm.blindDetail, "scenes", $$v)
                        },
                        expression: "blindDetail.scenes",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("售卖"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("赠品\\空投\\合成"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "盲盒名称：", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入盲盒名称",
                              disabled: _vm.editFlag,
                            },
                            model: {
                              value: _vm.blindDetail.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.blindDetail, "name", $$v)
                              },
                              expression: "blindDetail.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品标签：", prop: "tagIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                multiple: "",
                                placeholder: "请选择所属标签",
                              },
                              model: {
                                value: _vm.blindDetail.tagIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.blindDetail, "tagIds", $$v)
                                },
                                expression: "blindDetail.tagIds",
                              },
                            },
                            _vm._l(_vm.tagList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "转赠规则：",
                            prop: "transferRuleId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择转赠规则" },
                              model: {
                                value: _vm.blindDetail.transferRuleId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.blindDetail,
                                    "transferRuleId",
                                    $$v
                                  )
                                },
                                expression: "blindDetail.transferRuleId",
                              },
                            },
                            _vm._l(_vm.transferList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售数量：", prop: "showNumber" } },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.showNumber))]),
                          _vm._v(" "),
                          _c("el-switch", {
                            staticClass: "ml20",
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#909399",
                            },
                            model: {
                              value: _vm.blindDetail.isShowTotalNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.blindDetail,
                                  "isShowTotalNumber",
                                  $$v
                                )
                              },
                              expression: "blindDetail.isShowTotalNumber",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v("（前台不展示发售数量）")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "限购数量：",
                            prop: "purchaseQuantity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入限购数量" },
                            model: {
                              value: _vm.blindDetail.purchaseQuantity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.blindDetail,
                                  "purchaseQuantity",
                                  _vm._n($$v)
                                )
                              },
                              expression: "blindDetail.purchaseQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单次限购：",
                            prop: "oneCountPurchaseQuantity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入单次限购" },
                            model: {
                              value: _vm.blindDetail.oneCountPurchaseQuantity,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.blindDetail,
                                  "oneCountPurchaseQuantity",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "blindDetail.oneCountPurchaseQuantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "锁定数量：", prop: "lockStock" } },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticClass: "flex-one" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    disabled: _vm.blindDetail.scenes == 2,
                                    placeholder: "请输入锁定数量",
                                  },
                                  model: {
                                    value: _vm.blindDetail.lockStock,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.blindDetail,
                                        "lockStock",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "blindDetail.lockStock",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", [_vm._v("（只做为控制售罄使用）")]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售时间：", prop: "startTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              placeholder: "请选择发售时间",
                              align: "right",
                            },
                            model: {
                              value: _vm.blindDetail.startTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.blindDetail, "startTime", $$v)
                              },
                              expression: "blindDetail.startTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发售价格：", prop: "price" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "number",
                                placeholder: "请输入盲盒价格",
                                disabled: _vm.editFlag,
                              },
                              on: { input: _vm.changePrice },
                              model: {
                                value: _vm.blindDetail.price,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.blindDetail,
                                    "price",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "blindDetail.price",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "优惠价格：", prop: "vipPrice" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "number",
                                placeholder: "优惠价格",
                                disabled: _vm.editFlag,
                              },
                              model: {
                                value: _vm.blindDetail.vipPrice,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.blindDetail,
                                    "vipPrice",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "blindDetail.vipPrice",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "购买限制：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: {
                                  change: function ($event) {
                                    _vm.blindDetail.snapshotId = ""
                                  },
                                },
                                model: {
                                  value: _vm.blindDetail.crowdRestrictionType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.blindDetail,
                                      "crowdRestrictionType",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "blindDetail.crowdRestrictionType",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex align-items-c" },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("不限制"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("新用户可购买"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("人群可购买"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mr10",
                                        staticStyle: { width: "250px" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              placeholder: "选择名单",
                                              clearable: "",
                                              disabled:
                                                _vm.blindDetail
                                                  .crowdRestrictionType != 2,
                                            },
                                            model: {
                                              value: _vm.blindDetail.snapshotId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.blindDetail,
                                                  "snapshotId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "blindDetail.snapshotId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.nameListsList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  value: item.id,
                                                  label: item.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("自定义人群数量"),
                                    ]),
                                    _vm._v(" "),
                                    _vm.blindDetail.crowdRestrictionType == 3 &&
                                    _vm.blindDetail.crowdRestrictions &&
                                    _vm.blindDetail.crowdRestrictions.length
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                  disabled:
                                                    _vm.blindDetail
                                                      .crowdRestrictionType !=
                                                    3,
                                                },
                                                on: {
                                                  click: _vm.delUploadAction,
                                                },
                                              },
                                              [_vm._v("已导入 删除名单")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _c(
                                              "el-upload",
                                              {
                                                ref: "uploadImg",
                                                staticClass: "upload-demo mr10",
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                                attrs: {
                                                  action: "#",
                                                  limit: 1,
                                                  "show-file-list": false,
                                                  "http-request": function (
                                                    file
                                                  ) {
                                                    return _vm.airdropExcels(
                                                      file
                                                    )
                                                  },
                                                  disabled:
                                                    _vm.blindDetail
                                                      .crowdRestrictionType !=
                                                    3,
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "mini",
                                                      disabled:
                                                        _vm.blindDetail
                                                          .crowdRestrictionType !=
                                                        3,
                                                    },
                                                  },
                                                  [_vm._v("导入名单")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                  disabled:
                                                    _vm.blindDetail
                                                      .crowdRestrictionType !=
                                                    3,
                                                },
                                                on: { click: _vm.downLoad },
                                              },
                                              [_vm._v("下载导入模板")]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分抵扣：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.blindDetail.pointsDeductionType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.blindDetail,
                                    "pointsDeductionType",
                                    $$v
                                  )
                                },
                                expression: "blindDetail.pointsDeductionType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("抵扣"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("兑换"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "积分消耗：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("span", [_vm._v("积分类型：")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "选择积分类型" },
                                    model: {
                                      value: _vm.blindDetail.scoreTypeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.blindDetail,
                                          "scoreTypeId",
                                          $$v
                                        )
                                      },
                                      expression: "blindDetail.scoreTypeId",
                                    },
                                  },
                                  _vm._l(_vm.typeList, function (m, i) {
                                    return _c("el-option", {
                                      key: "type" + i,
                                      attrs: { value: m.id, label: m.name },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("积分数量："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200",
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.blindDetail.pointsDeduction,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.blindDetail,
                                      "pointsDeduction",
                                      $$v
                                    )
                                  },
                                  expression: "blindDetail.pointsDeduction",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("积分"),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "ml20" }, [
                              _vm._v("动态积分数量"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "ml10" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#ccc",
                                  },
                                  model: {
                                    value: _vm.blindDetail.hasAutoScore,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.blindDetail,
                                        "hasAutoScore",
                                        $$v
                                      )
                                    },
                                    expression: "blindDetail.hasAutoScore",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w200 ml20" },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !_vm.blindDetail.hasAutoScore,
                                    },
                                    model: {
                                      value: _vm.blindDetail.autoScoreType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.blindDetail,
                                          "autoScoreType",
                                          $$v
                                        )
                                      },
                                      expression: "blindDetail.autoScoreType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: 1,
                                        label: "按寄售市场最低价",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        value: 2,
                                        label: "按寄售市场均价",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticClass: "w200 ml10",
                                attrs: {
                                  type: "number",
                                  disabled: !_vm.blindDetail.hasAutoScore,
                                },
                                model: {
                                  value: _vm.blindDetail.autoCoefficient,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.blindDetail,
                                      "autoCoefficient",
                                      $$v
                                    )
                                  },
                                  expression: "blindDetail.autoCoefficient",
                                },
                              },
                              [
                                _c("template", { slot: "append" }, [
                                  _vm._v("倍"),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "积分使用人群：" } },
                        [
                          _c("div", { staticClass: "flex align-items-c" }, [
                            _c(
                              "div",
                              { staticStyle: { width: "400px" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "选择名单",
                                      clearable: "",
                                      multiple: "",
                                    },
                                    model: {
                                      value: _vm.blindDetail.airdropIds,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.blindDetail,
                                          "airdropIds",
                                          $$v
                                        )
                                      },
                                      expression: "blindDetail.airdropIds",
                                    },
                                  },
                                  _vm._l(_vm.nameListsList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.name,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "ml10",
                                staticStyle: {
                                  color: "#606266",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("（不选为全部用户可用）")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-form-item", { attrs: { label: "开盒时间：" } }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items-c" },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#13ce66",
                                "inactive-color": "#909399",
                              },
                              model: {
                                value: _vm.blindDetail.hasOpenTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.blindDetail, "hasOpenTime", $$v)
                                },
                                expression: "blindDetail.hasOpenTime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "w300 ml10" },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "请选择发售时间",
                                    align: "right",
                                    disabled: !_vm.blindDetail.hasOpenTime,
                                  },
                                  model: {
                                    value: _vm.blindDetail.openTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.blindDetail, "openTime", $$v)
                                    },
                                    expression: "blindDetail.openTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              !_vm.id
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否应用专辑内全部藏品：",
                            prop: "showNumber",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#909399",
                            },
                            model: {
                              value: _vm.blindDetail.hasAllProduct,
                              callback: function ($$v) {
                                _vm.$set(_vm.blindDetail, "hasAllProduct", $$v)
                              },
                              expression: "blindDetail.hasAllProduct",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "藏品列表：", prop: "products" } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.proLoading,
                          expression: "proLoading",
                        },
                      ],
                      staticClass: "product-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.paginatedProducts,
                        size: "mini",
                        border: "",
                        "highlight-current-row": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "排序", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-input-number", {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    size: "small",
                                    min: 0,
                                    controls: false,
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.editSortAction(
                                        val,
                                        scope.$index
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.sort,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "sort", $$v)
                                    },
                                    expression: "scope.row.sort",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "图片" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: { src: row.image },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "稀有度" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: row.rareId,
                                      callback: function ($$v) {
                                        _vm.$set(row, "rareId", $$v)
                                      },
                                      expression: "row.rareId",
                                    },
                                  },
                                  _vm._l(_vm.ssrListArray, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "展示概率", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "80%" },
                                  model: {
                                    value: row.showProbability,
                                    callback: function ($$v) {
                                      _vm.$set(row, "showProbability", $$v)
                                    },
                                    expression: "row.showProbability",
                                  },
                                }),
                                _vm._v("%\n            "),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "展示数量", "min-width": "100" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("el-input", {
                                  staticStyle: { width: "80%" },
                                  model: {
                                    value: row.showCount,
                                    callback: function ($$v) {
                                      _vm.$set(row, "showCount", $$v)
                                    },
                                    expression: "row.showCount",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "数字藏品名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_c("div", [_vm._v(_vm._s(row.name))])]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "隐藏款", "min-width": "300" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-color": "#13ce66",
                                    "inactive-color": "#909399",
                                  },
                                  model: {
                                    value: scope.row.hasHide,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hasHide", $$v)
                                    },
                                    expression: "scope.row.hasHide",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "item-align-center",
                                    staticStyle: {
                                      display: "inline-block",
                                      position: "relative",
                                      top: "8px",
                                      "margin-left": "8px",
                                      color: "#606266",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-img-view item-flex-center",
                                        on: {
                                          click: function ($event) {
                                            return _vm.modalPicTapRow(
                                              1,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        scope.row.hideImage
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "del-upload-img",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delImgRow(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "https://saas.cdn.yunzongbu.cn/image/20211013/8948a1e46cc204b122ca07eeb72f48d4.png",
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.hideImage
                                          ? _c("img", {
                                              staticStyle: {
                                                width: "36px",
                                                height: "36px",
                                              },
                                              attrs: {
                                                src:
                                                  scope.row.hideImage + "!120a",
                                              },
                                            })
                                          : _c("img", {
                                              staticClass: "upload-icon",
                                              attrs: {
                                                src: "https://saas.cdn.yunzongbu.cn/image/20211012/5a204fff4943279494d425cf420a504d.png",
                                                alt: "",
                                              },
                                            }),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "w200 ml20",
                                  staticStyle: {
                                    display: "inline-block",
                                    position: "relative",
                                    top: "-3px",
                                  },
                                  attrs: {
                                    size: "small",
                                    placeholder: "自定义隐藏款名称",
                                  },
                                  model: {
                                    value: scope.row.hideName,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "hideName", $$v)
                                    },
                                    expression: "scope.row.hideName",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "预留库存/拆出" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(row.reserveStock) +
                                      " / " +
                                      _vm._s(row.outCount)
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "投放数量", "min-width": "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                !row.id || !_vm.blindDetail.isIssuance
                                  ? _c("el-input", {
                                      attrs: { placeholder: "请输入投放数量" },
                                      on: { blur: _vm.putCountBlur },
                                      model: {
                                        value: row.putCount,
                                        callback: function ($$v) {
                                          _vm.$set(row, "putCount", $$v)
                                        },
                                        expression: "row.putCount",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                row.id && _vm.blindDetail.isIssuance
                                  ? _c("span", [_vm._v(_vm._s(row.putCount))])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", fixed: "right", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                !scope.row.id || !_vm.blindDetail.isIssuance
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delAction(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.id && _vm.blindDetail.isIssuance
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "mini" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addAction(scope)
                                          },
                                        },
                                      },
                                      [_vm._v("追加投放")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                      staticStyle: { "justify-content": "space-between" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mt10",
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.addProData },
                        },
                        [_vm._v("添加")]
                      ),
                      _vm._v(" "),
                      _c("el-pagination", {
                        staticClass: "mt10",
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-sizes": [10, 20, 50],
                          "page-size": _vm.pageSize,
                          layout: "total, sizes, prev, pager, next",
                          total: _vm.blindDetail.products.length,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "详细介绍：", prop: "description" } },
                [
                  _c("Tinymce", {
                    ref: "editor",
                    attrs: { height: 400 },
                    model: {
                      value: _vm.blindDetail.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.blindDetail, "description", $$v)
                      },
                      expression: "blindDetail.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visiblePro,
            "close-on-click-modal": false,
            title: "选择藏品",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visiblePro = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticStyle: {
                    width: "200px !important",
                    "margin-bottom": "10px",
                  },
                  attrs: { placeholder: "专辑筛选", clearable: "" },
                  on: { change: _vm.nftCollectionListMethod },
                  model: {
                    value: _vm.nftData.seriesId,
                    callback: function ($$v) {
                      _vm.$set(_vm.nftData, "seriesId", $$v)
                    },
                    expression: "nftData.seriesId",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 0, label: "非专辑" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 1, label: "专辑" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.nftLoading,
                      expression: "nftLoading",
                    },
                  ],
                  staticClass: "nft-table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.nftData.data,
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "50" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-popover",
                              {
                                staticClass: "tabPop",
                                attrs: {
                                  placement: "top-start",
                                  width: "100",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "spBlock onHand",
                                      class: { check: _vm.chkName === "dan" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onHandle(
                                            "dan",
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选中本页")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-checkbox", {
                                  attrs: {
                                    slot: "reference",
                                    value:
                                      (_vm.chkName === "dan" &&
                                        _vm.checkedPage.indexOf(
                                          _vm.queryParams.pageNum
                                        ) > -1) ||
                                      _vm.chkName === "duo",
                                  },
                                  on: { change: _vm.changeType },
                                  slot: "reference",
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: {
                                value:
                                  _vm.checkedIds.indexOf(scope.row.id) > -1 ||
                                  (_vm.chkName === "duo" &&
                                    _vm.noChecked.indexOf(scope.row.id) === -1),
                              },
                              on: {
                                change: function (v) {
                                  return _vm.changeOne(v, scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "图片" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: { src: row.coverPicture },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "数字藏品名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.name))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "价格" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.price))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "预留/预留库存" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(row.reservedQuantity) +
                                  " / " +
                                  _vm._s(row.reserveStock)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "起售时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("div", [_vm._v(_vm._s(row.startTime))])]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-pagination", {
            staticClass: "mt20",
            attrs: {
              "page-sizes": [10, 20, 40, 60, 80, 100, 200, 400],
              "page-size": Number(_vm.queryParams.pageSize),
              "current-page": Number(_vm.queryParams.pageNum),
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.nftData.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.nftCollectionListMethod,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visiblePro = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSelectAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visibleAdd,
            "close-on-click-modal": false,
            title: "追加投放",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleAdd = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入追加投放" },
            model: {
              value: _vm.addForm.stock,
              callback: function ($$v) {
                _vm.$set(_vm.addForm, "stock", _vm._n($$v))
              },
              expression: "addForm.stock",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "item-flex-center mt20" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visibleAdd = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmAddAction },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }