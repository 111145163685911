"use strict";

var _interopRequireDefault = require("/root/workspace/java_admin_Rm9R/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
var _settingMer = _interopRequireDefault(require("@/libs/settingMer"));
var _product = require("@/api/product");
var _nft = require("@/api/nft");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AddBlindBox",
  filters: {
    formatProductType: function formatProductType(val) {
      var res = "";
      switch (val) {
        case 0:
          res = "数字藏品";
          break;
        case 1:
          res = "数字盲盒";
          break;
        case 2:
          res = "专辑";
          break;
        default:
          break;
      }
      return res;
    }
  },
  data: function data() {
    var _this = this;
    return {
      formValidate: {
        name: "",
        photoTime: "",
        products: [],
        snapshotRange: null,
        snapshotType: 0,
        excelImportList: [],
        //导入数据
        moneyType: 0
      },
      moneyTime: [],
      ruleValidate: {
        name: [{
          required: true,
          message: "请输入快照名称",
          trigger: "blur"
        }],
        photoTime: [{
          required: true,
          message: "请选择快照时间",
          trigger: "change"
        }]
      },
      tableData: {
        data: [],
        total: 0
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          if (_this.formValidate.snapshotRange == 3) {
            return time.getTime() < Date.now() || time.getTime() > Date.now() + 30 * 24 * 3600 * 1000;
          }
          return time.getTime() < Date.now();
        }
      },
      listLoading: false,
      myHeaders: {
        "X-Token": (0, _auth.getToken)()
      },
      productForm: {
        types: 1,
        pageNum: 1,
        pageSize: 20
      },
      productList: {
        data: [],
        total: 0
      },
      visible: false,
      chooseList: [],
      visibleTitle: "",
      uploadFlag: false
    };
  },
  computed: {
    fileUrl: function fileUrl() {
      return _settingMer.default.https + "/excel/import";
    }
  },
  created: function created() {
    if (this.$route.query.id) {
      this.getBoxDetail(this.$route.query.id);
    }
  },
  methods: {
    productDelete: function productDelete(row) {
      var index = this.formValidate.products.findIndex(function (res) {
        return res.id == row.id;
      });
      if (index != -1) {
        this.formValidate.products.splice(index, 1);
      }
    },
    // 下载
    downLoad: function downLoad() {
      window.open("https://java-test-cdn.yunzongbu.cn/user.xlsx");
    },
    uploadRequest: function uploadRequest(file) {
      this.uploadFlag = false;
      var formDataFile = new FormData();
      formDataFile.append("file", file.file);
      this.snapshotExcelApiMethod(formDataFile, true);
    },
    snapshotExcelApiMethod: function snapshotExcelApiMethod(formDataFile) {
      var _this2 = this;
      (0, _product.snapshotExcelApi)(formDataFile, {
        headers: {
          "Content-Type": "form-data"
        }
      }).then(function (res) {
        _this2.formValidate.excelImportList = res.data.excelImportList;
        _this2.formValidate.num = res.data.num;
        _this2.uploadFlag = true;
      }).catch(function (msg) {
        // loading.close();
      });
    },
    getBoxDetail: function getBoxDetail(id) {
      var _this3 = this;
      (0, _product.snapshotDetailApi)(id).then(function (res) {
        _this3.formValidate = res.data;
        if (res.data.moneyStartTime) {
          _this3.moneyTime[0] = res.data.moneyStartTime;
          _this3.moneyTime[1] = res.data.moneyEndTime;
        }
        if (_this3.formValidate.products && _this3.formValidate.products.length) {
          _this3.formValidate.products.forEach(function (item) {
            item.coverPicture = item.pictureUrl;
          });
        }
      });
    },
    submitForm: function submitForm() {
      var _this4 = this;
      this.$refs.formValidate.validate(function (valid) {
        if (valid) {
          var data = JSON.parse(JSON.stringify(_this4.formValidate));
          if (!data.products) data.products = [];
          data.isSatisfyBoth = data.isSatisfyBoth ? true : false;
          console.log(_this4.formValidate.snapshotRange, 259);
          if (_this4.formValidate.snapshotRange != 2) {
            if (!(data.excelImportList && data.excelImportList.length)) {
              if (data.snapshotRange == null) {
                _this4.$message.error("请选择拍照范围");
                return false;
              }
              if (data.products.length == 0 && (data.snapshotRange == 0 || data.snapshotRange == 3)) {
                _this4.$message.error("拍照条件不能为空");
                return false;
              }
              if (data.totalPosition == "" && data.snapshotRange == 1) {
                _this4.$message.error("拍照条件不能为空");
                return false;
              }
              if (data.totalConsumption == "" && data.snapshotRange == 2) {
                _this4.$message.error("拍照条件不能为空");
                return false;
              }
            }
          }
          if (_this4.formValidate.snapshotRange == 2) {
            if (_this4.moneyTime.length) {
              data.moneyStartTime = _this4.moneyTime[0];
              data.moneyEndTime = _this4.moneyTime[1];
            }
          }
          console.log(data.products.length, data.totalPosition, data.totalConsumption, 278);
          // 添加类型 1为excel导入 2为商品添加 3两种方式一起使用
          if (data.excelImportList && data.excelImportList.length && (data.products.length != 0 || data.totalPosition || data.totalConsumption)) {
            data.type = 3;
          } else if (data.products.length != 0 || data.totalPosition || data.totalConsumption) {
            data.type = 2;
          } else if (data.excelImportList && data.excelImportList.length) {
            data.type = 1;
          }
          var action = _product.snapshotCreateApi;
          if (_this4.$route.query.id) {
            action = _product.snapshotUpdateApi;
          }
          action(data).then(function (res) {
            _this4.$message.success("操作成功");
            _this4.$router.back();
          }).catch(function (res) {});
        }
      });
    },
    backAction: function backAction() {
      this.$router.go(-1);
    },
    addProducts: function addProducts(type) {
      this.visible = true;
      this.productForm.types = type;
      this.visibleClose();
      this.goSearch();
    },
    goSearch: function goSearch() {
      if (this.productForm.types == 1) {
        this.visibleTitle = "添加数字藏品";
        this.nftCollectionListApiMethod();
      } else if (this.productForm.types == 2) {
        this.visibleTitle = "添加数字盲盒";
        this.productBoxListApiMethod();
      } else if (this.productForm.types == 3) {
        this.visibleTitle = "添加专辑";
        this.nftCollectionAlbumListApiMethod();
      }
    },
    productFormSizeChange: function productFormSizeChange(val) {
      this.productForm.pageSize = val;
      this.goSearch();
    },
    productFormpageChange: function productFormpageChange(val) {
      this.productForm.pageNum = val;
      this.goSearch();
    },
    nftCollectionListApiMethod: function nftCollectionListApiMethod() {
      var _this5 = this;
      (0, _nft.nftCollectionListApi)(this.productForm).then(function (res) {
        _this5.productList.data = res.rows;
        _this5.productList.total = res.total;
        _this5.productList.data.forEach(function (element) {
          element.productType = 0;
          element.choose = false;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    productBoxListApiMethod: function productBoxListApiMethod() {
      var _this6 = this;
      (0, _nft.productBoxListApi)(this.productForm).then(function (res) {
        _this6.productList.data = res.rows;
        _this6.productList.total = res.total;
        _this6.productList.data.forEach(function (element) {
          element.productType = 1;
          element.choose = false;
          element.coverPicture = element.image;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    nftCollectionAlbumListApiMethod: function nftCollectionAlbumListApiMethod() {
      var _this7 = this;
      (0, _nft.nftCollectionAlbumListApi)(this.productForm).then(function (res) {
        _this7.productList.data = res.rows;
        _this7.productList.total = res.total;
        _this7.productList.data.forEach(function (element) {
          element.productType = 2;
          element.choose = false;
          element.coverPicture = element.image;
          element.productName = element.name;
        });
      }).catch(function (res) {});
    },
    chooseRow: function chooseRow(row, index) {
      var obj = {
        productType: row.productType,
        productName: row.productName,
        productId: row.id,
        relationId: row.productId || "",
        coverPicture: row.coverPicture,
        id: row.id,
        checkType: 0
      };
      if (!row.choose) {
        this.chooseList.push(obj);
      } else {
        var _index = this.chooseList.findIndex(function (res) {
          return res.productId == row.id;
        });
        if (_index != -1) {
          this.chooseList.splice(_index, 1);
        }
      }
      // console.log('chooseList', this.chooseList)
      this.productList.data[index].choose = !row.choose;
      this.$forceUpdate();
    },
    confirmAction: function confirmAction() {
      this.formValidate.products = this.formValidate.products.concat(this.chooseList);
      var arr = this.formValidate.products.filter(function (element, index, self) {
        return self.findIndex(function (el) {
          return el.productId == element.productId;
        }) === index; // 如果是根据name去重就江id改为name
      });
      this.formValidate.products = arr;
      this.visible = false;
    },
    visibleClose: function visibleClose() {
      this.chooseList = [];
    }
  }
};